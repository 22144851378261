/*
Author       : Dreamguys
Template Name: Doccure - Bootstrap Template
Version      : 1.3
*/

/*============================
 [Table of CSS]

1. General
2. Table
3. Bootstrap Classes
4. Avatar
5. Select2
6. Nav Tabs
7. Modal
8. Components
9. Slick Slider
10. Focus Label
11. Header
12. Mobile Menu
13. Footer
14. Login
15. Home
16. Search
17. Doctor Profile
18. Booking
19. Checkout
20. Booking Success
21. Invoice View
22. Schedule Timings
23. Doctor Dashboard
24. Patient Profile
25. Add Billing
26. Chat
27. Doctor Profile Settings
28. Calendar
29. Patient Dashboard
30. Profile Settings
31. Appoitment List
32. Reviews
33. Voice call
34. Video Call
35. Outgoing Call
36. Incoming Call
37. Terms and Conditions
38. Blog
39. Blog Details
40. Blog Grid
41. Map List & Grid
42. Loader
43. Responsive
44. Notifications

========================================*/

/*-----------------
	1. General
-----------------------*/

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800;900&display=swap');
@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
	src: local('Material Icons'),
	local('MaterialIcons-Regular'),
	url(../fonts/MaterialIcons-Regular.woff2) format('woff2'),
	url(../fonts/MaterialIcons-Regular.woff) format('woff'),
	url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
}
.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga';
}

html {
    height: 100%;
}
body {
	background-color: #f8f9fa;
	color: #272b41;
    font-family: 'Nunito', sans-serif;
	font-size: 17px;
    height: 100%;
	overflow-x: hidden;
}
h1, h2, h3, h4, h5, h6 {
	color: #272b41;
	font-weight: 500;
}
.h1, h1 {
    font-size: 2.25rem;
}
.h2, h2 {
    font-size: 1.875rem;
}
.h3, h3 {
    font-size: 1.5rem;
}
.h4, h4 {
    font-size: 1.125rem;
}
.h5, h5 {
    font-size: 1rem;
}
.h6, h6 {
    font-size: 0.875rem;
}
img {

	max-width: 100%;

}
.photoproperty
{
	padding-top: 100px;
}
.photoproperty_1
{
	padding-bottom:50px;
	padding-top: 70px;
	width: 370px;

}
.aboutus-col .about-right.name_property

{
	padding-top: 100px;
}

.rounded float-right.img {
	
	max-width: 100%;
	padding-top: 80px;
}
a {
    color: #2E3842;
}
a:hover {
	color: #e97a24;
}
a:hover,
a:active,
a:focus {
	outline: none;
	text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
input,
button,
a {
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
}
button:focus {
	outline: 0;
}
input[type=text],
input[type=password] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.btn.focus, .btn:focus {
	box-shadow: inherit;
}
.form-control {
    border-color: #dcdcdc;
    color: #333;
	font-size: 15px;
    min-height: 46px;
	padding: 6px 15px;
}
.table .form-control {
	font-size: 14px;
	min-height: 38px;
}
.container-fluid {
	padding-left: 30px;
	padding-right: 30px;
}
.form-control::-webkit-input-placeholder {
	color: #858585;
	font-size: 14px;
}
.form-control::-moz-placeholder {
	color: #858585;
	font-size: 14px;
}
.form-control:-ms-input-placeholder {
	color: #858585;
	font-size: 14px;
}
.form-control::-ms-input-placeholder {
	color: #858585;
	font-size: 14px;
}
.form-control::placeholder {
	color: #858585;
	font-size: 14px;
}
.list-group-item {
	border: 1px solid #f0f0f0;
}
.content {
	min-height: 200px;
	padding: 30px 0 0;
}
.container {
	max-width: 100%;
	padding: 0 30px;
}

/*-----------------
	2. Table
-----------------------*/

.table {
	color: #272b41;
	max-width: 100%;
	margin-bottom: 0;
	width: 100%;
}
.table-striped > tbody > tr:nth-of-type(2n+1) {
	background-color: #f8f9fa;
}
.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
	border-top: 0;
	padding: 10px 8px;
}
.table-nowrap td,
.table-nowrap th {
	white-space: nowrap
}
.table.dataTable {
	border-collapse: collapse !important;
}
table.table td h2 {
	display: inline-block;
	font-size: inherit;
	font-weight: 400;
	margin: 0;
	padding: 0;
	vertical-align: middle;
}
table.table td h2.table-avatar {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
	white-space: nowrap;
}
table.table td h2 a {
	color: #272b41;
}
table.table td h2 a:hover {
	color: #e97a24;
}
table.table td h2 span {
	color: #888;
	display: block;
	font-size: 12px;
	margin-top: 3px;
}
.table thead {
	border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.table thead tr th {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table tbody tr {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table tbody tr:last-child {
	border-color: transparent;
}
.table.table-center td,
.table.table-center th {
	vertical-align: middle;
	font-size: 15px;
}
.table-hover tbody tr:hover {
	background-color: #f7f7f7;
}
.table-hover tbody tr:hover td {
	color: #474648;
}
.table-striped thead tr {
	border-color: transparent;
}
.table-striped tbody tr {
	border-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
	background-color: rgba(255, 255, 255, 0.3);
}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(235, 235, 235, 0.4);
}
.table-bordered {
	border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered th,
.table-bordered td {
	border-color: rgba(0, 0, 0, 0.05);
}
.card-table .card-body {
    padding: 0;
}
.card-table .card-body .table > thead > tr > th {
    border-top: 0;
}
.card-table .table tr td:first-child,
.card-table .table tr th:first-child {
    padding-left: 1.5rem;
}
.card-table .table tr td:last-child,
.card-table .table tr th:last-child {
    padding-right: 1.5rem;
}
.card-table .table td, .card-table .table th {
    border-top: 1px solid #e2e5e8;
    padding: 1rem 0.75rem;
    white-space: nowrap;
}

/*-----------------
	3. Bootstrap Classes
-----------------------*/

.btn.focus, .btn:focus {
    box-shadow: unset;
}
.btn-white {
	background-color: #fff;
	border: 1px solid #ccc;
	color: #272b41;
}
.btn.btn-rounded {
	border-radius: 50px;
}
.bg-primary,
.badge-primary {
	background-color: #09e5ab !important;
}
a.bg-primary:focus, 
a.bg-primary:hover, 
button.bg-primary:focus, 
button.bg-primary:hover {
    background-color: #09dca4 !important;
}
.bg-success,
.badge-success {
	background-color: #00e65b !important;
}
a.bg-success:focus, 
a.bg-success:hover, 
button.bg-success:focus, 
button.bg-success:hover {
    background-color: #00cc52 !important;
}
.bg-info,
.badge-info {
	background-color: #3065b5 !important;
}
a.bg-info:focus, 
a.bg-info:hover, 
button.bg-info:focus, 
button.bg-info:hover {
    background-color: #028ee1 !important;
}
.bg-warning,
.badge-warning {
	background-color: #ffbc34 !important;
}
a.bg-warning:focus, 
a.bg-warning:hover, 
button.bg-warning:focus, 
button.bg-warning:hover {
    background-color: #e9ab2e !important;
}
.bg-danger,
.badge-danger {
	background-color: #ff0100 !important;
}
a.bg-danger:focus, 
a.bg-danger:hover, 
button.bg-danger:focus, 
button.bg-danger:hover {
    background-color: #e63333 !important;
}
.bg-white {
	background-color: #fff;
}
.bg-purple,
.badge-purple {
    background-color: #9368e9 !important;
}
.text-primary,
.dropdown-menu > li > a.text-primary {
	color: #e97a24 !important;
}
.text-success,
.dropdown-menu > li > a.text-success {
	color: #00cc52 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
	color: #ff0100 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
	color: #e97a24 !important;
}
.text-warning,
.dropdown-menu > li > a.text-warning {
	color: #09e5ab !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
	color: #7460ee !important;
}
.text-muted {
    color: #757575 !important;
}
.text-alert {
    color: #3065b5 !important;
}
.btn-primary {
	background-color: #e97a24;
	border: 1px solid #e97a24;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
	background-color: #3065b5;
	border: 1px solid #3065b5;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
	background-color: #e97a24;
	border: 1px solid #e97a24;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
	background-color: #e97a24;
	border-color: #e97a24;
	color: #fff;
}
.btn-primary.active:focus:not(:disabled):not(.disabled), 
.btn-primary:active:focus:not(:disabled):not(.disabled), 
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-primary.disabled, .btn-primary:disabled {
    background-color: #e97a24;
    border-color: #e97a24;
    color: #fff;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled), 
.btn-secondary:active:focus:not(:disabled):not(.disabled), 
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success {
	background-color: #00e65b;
	border: 1px solid #00e65b
}
.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
	background-color: #00cc52;
	border: 1px solid #00cc52;
	color: #fff;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
	background-color: #00cc52;
	border: 1px solid #00cc52
}
.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
	background-color: #00cc52;
	border-color: #00cc52;
	color: #fff;
}
.btn-success.active:focus:not(:disabled):not(.disabled), 
.btn-success:active:focus:not(:disabled):not(.disabled), 
.show > .btn-success.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success.disabled, .btn-success:disabled {
    background-color: #00e65b;
    border-color: #00e65b;
    color: #fff;
}
.btn-info {
	background-color: #009efb;
	border: 1px solid #009efb
}
.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
	background-color: #028ee1;
	border-color: #028ee1;
	color: #fff;
}
.btn-info.active:focus:not(:disabled):not(.disabled), 
.btn-info:active:focus:not(:disabled):not(.disabled), 
.show > .btn-info.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-info.disabled, .btn-info:disabled {
    background-color: #009efb;
    border-color: #009efb;
    color: #fff;
}
.btn-warning {
	background-color: #ffbc34;
	border: 1px solid #ffbc34
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle {
	background-color: #e9ab2e;
	border-color: #e9ab2e;
	color: #fff;
}
.btn-warning.active:focus:not(:disabled):not(.disabled), 
.btn-warning:active:focus:not(:disabled):not(.disabled), 
.show > .btn-warning.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffbc34;
    border-color: #ffbc34;
    color: #fff;
}
.btn-danger {
	background-color: #ff0100;
	border: 1px solid #ff0100;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
	background-color: #e63333;
	border: 1px solid #e63333;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
	background-color: #e63333;
	border: 1px solid #e63333;
}
.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #e63333;
	border-color: #e63333;
	color: #fff;
}
.btn-danger.active:focus:not(:disabled):not(.disabled), 
.btn-danger:active:focus:not(:disabled):not(.disabled), 
.show > .btn-danger.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-danger.disabled, .btn-danger:disabled {
    background-color: #f62d51;
    border-color: #f62d51;
    color: #fff;
}
.btn-light.active:focus:not(:disabled):not(.disabled), 
.btn-light:active:focus:not(:disabled):not(.disabled), 
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-dark.active:focus:not(:disabled):not(.disabled), 
.btn-dark:active:focus:not(:disabled):not(.disabled), 
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-outline-primary {
	color: #09e5ab;
	border-color: #09e5ab;
}
.btn-outline-primary:hover {
	background-color: #09e5ab;
	border-color: #09e5ab;
}
.btn-outline-primary:focus, 
.btn-outline-primary.focus {
	box-shadow: none;
}
.btn-outline-primary.disabled, 
.btn-outline-primary:disabled {
	color: #09e5ab;
	background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, 
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	background-color: #09e5ab;
	border-color: #09e5ab;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, 
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-success {
	color: #00e65b;
	border-color: #00e65b;
}
.btn-outline-success:hover {
	background-color: #00e65b;
	border-color: #00e65b;
}
.btn-outline-success:focus, .btn-outline-success.focus {
	box-shadow: none;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
	color: #00e65b;
	background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, 
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
	background-color: #00e65b;
	border-color: #00e65b;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, 
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-info {
	color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:hover {
	color: #fff;
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:focus, .btn-outline-info.focus {
	box-shadow: none;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
	background-color: transparent;
	color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active, 
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, 
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-warning {
	color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:hover {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
	box-shadow: none;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
	background-color: transparent;
	color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, 
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, 
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-danger {
	color: #ff0100;
	border-color: #ff0100;
}
.btn-outline-danger:hover {
	color: #fff;
	background-color: #ff0100;
	border-color: #ff0100;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
	box-shadow: none;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
	background-color: transparent;
	color: #ff0100;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, 
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
	background-color: #ff0100;
	border-color: #ff0100;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, 
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-light {
    color: #ababab;
    border-color: #e6e6e6;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #ababab;
}
.pagination > .active > a, 
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: #20c0f3;
	border-color: #20c0f3;
}
.pagination > li > a, 
.pagination > li > span {
	color: #3065b5;
}
.page-link:hover {
	color: #20c0f3;
}
.page-link:focus {
	box-shadow: unset;
}
.page-item.active .page-link {
	background-color: #3065b5;
	border-color: #3065b5;
}
.dropdown-menu {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    box-shadow: inherit;
	font-size: 14px;
    transform-origin: left top 0;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #0de0fe;
}
.navbar-nav .open .dropdown-menu {
	border: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}
.card {
    border: 1px solid #f0f0f0;
    margin-bottom: 1.875rem;
}
.card-body {
	padding: 1.5rem;
}
.card-title {
    margin-bottom: 15px;
}
.card-header {
	border-bottom: 1px solid #f0f0f0;
	padding: 1rem 1.5rem;
}
.card-footer {
	background-color: #fff;
	border-top: 1px solid #f0f0f0;
	padding: 1rem 1.5rem;
}
.card .card-header {
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
}
.card .card-header .card-title {
    margin-bottom: 0;
}
.btn-light {
	border-color: #e6e6e6;
	color: #a6a6a6;
}
.datepicker-icon {
    max-width: 253px;
    float: right;
    position: relative;
}
.datepicker-icon input {
    max-width: 253px;
    position: relative;
    padding-left: 40px;
    padding-right: 26px;
}
.datepicker-icon i {
    position: absolute;
    left: 6px;
    z-index: 1;
    top: 14px;
    width: 20px;
}
.datepicker-icon i.fas {
    right: 6px !important;
    left: auto;
}
.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
	background-color: #20c0f3;
	text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today::before{
	border-bottom-color: #20c0f3;
}
.bg-info-light {
	background-color: rgba(2, 182, 179,0.12) !important;
	color: #1db9aa !important;
}
.bg-primary-light {	
	background-color: rgb(243 199 40 / 17%) !important;
	color: #e97a24 !important;
}
.bg-danger-light {
	background-color: rgba(242, 17, 54,0.12) !important;
	color: #e63c3c !important;
}
.bg-warning-light {
	background-color: rgba(17, 148, 247,0.12) !important;
	color: #2196f3 !important;
}
.bg-success-light {
	background-color: rgba(15, 183, 107,0.12) !important;
	color: #26af48 !important;
}
.bg-purple-light {
	background-color: rgba(197, 128, 255,0.12) !important;
	color: #c580ff !important;
}
.bg-default-light {
	background-color: rgba(40, 52, 71,0.12) !important;
	color: #283447 !important;
}
.text-xs {
	font-size: .75rem !important;
}
.text-sm {
	font-size: .875rem !important;
}
.text-lg {
	font-size: 1.25rem !important;
}
.text-xl {
	font-size: 1.5rem !important;
}
.form-control:focus {
	border-color: #bbb;
	box-shadow: none;
	outline: 0 none;
}
.form-group {
	margin-bottom: 1.25rem;
}

/*-----------------
	4. Avatar
-----------------------*/

.avatar {
	position: relative;
	display: inline-block;
	width: 3rem;
	height: 3rem
}
.avatar > img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}
.avatar-title {
	width: 100%;
	height: 100%;
	background-color: #3065b5;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.avatar-away::before,
.avatar-offline::before,
.avatar-online::before {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 25%;
	height: 25%;
	border-radius: 50%;
	content: '';
	border: 2px solid #fff;
}
.avatar-online::before {
	background-color: #00e65b;
}
.avatar-offline::before {
	background-color: #ff0100;
}
.avatar-away::before {
	background-color: #ffbc34;
}
.avatar .border {
	border-width: 3px !important;
}
.avatar .rounded {
	border-radius: 6px !important;
}
.avatar .avatar-title {
	font-size: 18px;
}
.avatar-xs {
	width: 1.65rem;
	height: 1.65rem;
}
.avatar-xs .border {
	border-width: 2px !important;
}
.avatar-xs .rounded {
	border-radius: 4px !important;
}
.avatar-xs .avatar-title {
	font-size: 12px;
}
.avatar-xs.avatar-away::before,
.avatar-xs.avatar-offline::before,
.avatar-xs.avatar-online::before {
	border-width: 1px;
}
.avatar-sm {
	width: 2.5rem;
	height: 2.5rem;
}
.avatar-sm .border {
	border-width: 3px !important;
}
.avatar-sm .rounded {
	border-radius: 4px !important;
}
.avatar-sm .avatar-title {
	font-size: 15px;
}
.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
	border-width: 2px;
}
.avatar-lg {
	width: 3.75rem;
	height: 3.75rem;
}
.avatar-lg .border {
	border-width: 3px !important;
}
.avatar-lg .rounded {
	border-radius: 8px !important;
}
.avatar-lg .avatar-title {
	font-size: 24px;
}
.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
	border-width: 3px;
}
.avatar-xl {
	width: 5rem;
	height: 5rem;
}
.avatar-xl .border {
	border-width: 4px !important;
}
.avatar-xl .rounded {
	border-radius: 8px !important;
}
.avatar-xl .avatar-title {
	font-size: 28px;
}
.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
	border-width: 4px;
}
.avatar-xxl {
	width: 5.125rem;
	height: 5.125rem;
}
.avatar-xxl .border {
	border-width: 6px !important;
}
.avatar-xxl .rounded {
	border-radius: 8px !important;
}
.avatar-xxl .avatar-title {
	font-size: 30px;
}
.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
	border-width: 4px;
}
.avatar-group {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.avatar-group .avatar + .avatar {
	margin-left: -.75rem;
}
.avatar-group .avatar-xs + .avatar-xs {
	margin-left: -.40625rem;
}
.avatar-group .avatar-sm+.avatar-sm {
	margin-left: -.625rem;
}
.avatar-group .avatar-lg + .avatar-lg {
	margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
	margin-left: -1.28125rem;
}
.avatar-group .avatar:hover {
	z-index: 1;
}

/*-----------------
	5. Select2
-----------------------*/

.select2-results__option {
	padding: 6px 15px;
}
.select2-container .select2-selection--single {
	border: 1px solid #dcdcdc;
	height: 46px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 44px;
	right: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #dcdcdc transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #dcdcdc;
	border-width: 0 6px 6px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 15px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #272b41;
	font-size: 15px;
	font-weight: normal;
	line-height: 44px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #20c0f3;
}
.select2-container--default .select2-selection--multiple {
	border: 1px solid #dcdcdc;
	min-height: 46px;
}
.select2-dropdown {
	border-color: #dcdcdc;
}

/*-----------------
	6. Nav Tabs
-----------------------*/

/* .nav-tabs {
	border-bottom: 1px solid #f0f0f0;
} */
.card-header-tabs {
    border-bottom: 0;
}
.nav-tabs > li > a {
	margin-right: 0;
	color: #888;
	border-radius: 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
	border-color: transparent;
	color: #272b41;
}
.nav-tabs.nav-tabs-solid > li > a {
	color: #272b41;
}
.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
	background-color: #20c0f3;
	border-color: #20c0f3;
	color: #fff;
}
.tab-content {
	padding-top: 20px;
}
.nav-tabs .nav-link {
	border-radius: 0;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {

    border-color: transparent;
	color: #272b41;
}
.nav-tabs.nav-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a {
	color: #272b41;
}
.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
	background-color: #3065b5;
	border-color: #3065b5;
	color: #fff;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
	border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
	border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
	border-radius: 50px;
}
.nav-tabs-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li {
	margin-bottom: 0;
}
.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-top-color: #ddd;
}
.nav-tabs.nav-tabs-top > li+li > a {
	margin-left: 1px;
}
.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
	border-top-color: #3065b5;
}
.nav-tabs.nav-tabs-bottom > li > a.active, 
.nav-tabs.nav-tabs-bottom > li > a.active:hover, 
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
	border-bottom-width: 2px;
	border-color: transparent;
	border-bottom-color: #e97a24;
	background-color: transparent;
	transition: none 0s ease 0s; 
	-moz-transition: none 0s ease 0s; 
	-o-transition: none 0s ease 0s; 
	-ms-transition: none 0s ease 0s; 
	-webkit-transition: none 0s ease 0s;
}
.nav-tabs.nav-tabs-solid {
	background-color: #fafafa;
	border: 0;
}
.nav-tabs.nav-tabs-solid > li {
	margin-bottom: 0;
}
.nav-tabs.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
	background-color: #f5f5f5;
}
.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
	background-color: #f5f5f5;
	border-color: transparent;
}
.nav-tabs-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}

/*-----------------
	7. Modal
-----------------------*/

.modal {
	-webkit-overflow-scrolling: touch;
}
.modal-footer.text-center {
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.modal-footer.text-left {
	justify-content: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
}
.modal-dialog.modal-md {
    max-width: 600px;
}
.custom-modal .modal-content {
	border: 0;
    border-radius: 10px;
}
.custom-modal .modal-header {
    padding: 1.25rem;
}
.custom-modal .modal-footer {
    padding: 1.25rem;
}
.custom-modal .modal-body {
    padding: 1.25rem;
}
.custom-modal .close {
    background-color: #a0a0a0;
    border-radius: 50%;
    color: #fff;
    font-size: 17px;
    height: 20px;
    line-height: 20px;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 20px;
    top: 26px;
    width: 20px;
    z-index: 99;
}
.custom-modal .modal-title {
	font-size: 20px;
}
.modal-backdrop.show {
    opacity: 0.4;
	-webkit-transition-duration: 400ms;
	transition-duration: 400ms;
}
.modal .card {
	box-shadow: unset;
}

/*-----------------
	8. Components
-----------------------*/

.comp-header {
    margin-bottom: 1.875rem;
}
.comp-header .comp-title {
    color: #272b41;
}
.line {
    background-color: #e97a24;
    height: 2px;
    margin: 0;
    width: 60px;
}
.comp-buttons .btn {
	margin-bottom: 5px;
}
.pagination-box .pagination {
	margin-top: 0;
}
.comp-dropdowns .btn-group {
	margin-bottom: 5px;
}
.progress-example .progress {
	margin-bottom: 1.5rem;
}
.progress-xs {
	height: 4px;
}
.progress-sm {
	height: 15px;
}
.progress.progress-sm {
	height: 6px;
}
.progress.progress-md {
	height: 8px;
}
.progress.progress-lg {
	height: 18px;
}

/*-----------------
	9. Slick Slider
-----------------------*/

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;	
}
.doctors-col .slick-slider {
	padding-bottom: 5px;
}
.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    /* min-height: 1px; */
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    bottom: 0;
    display: block;
    width: 40px;
    height: 40px;
    padding: 0;
    transform: translateX(0%) rotate(-45deg);
    -webkit-transform: translateX(0%) rotate(-45deg);
    -ms-transform: translateX(0%) rotate(-45deg);
    cursor: pointer;	
    border: none;
    outline: none;
    background-color: #204174;
    width: 40px;
    height: 40px;
    border-radius: 5px 15px 5px 15px;
    margin: 0 -30px;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
	opacity: 1;
	/* color: #fff; */
	background-color: rgb(226, 220, 220);
	
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}
.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 10px;
    line-height: 1;
    opacity: .75;
    color: #e97a24;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: solid #e97a24;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
}
.slick-prev {
    left: 47%;
	z-index:1;
}
[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}
.slick-prev:before {
    content: '';
    transform: rotate(180deg);
  	-webkit-transform: rotate(180deg);
}
[dir='rtl'] .slick-prev:before {
    content: ''; 
    transform: rotate(-45deg);
    -webkit-transform: rotate(0deg);
}
.slick-next {
    right: 47%;
}
[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}
.slick-next:before {
    content: '';
    transform: rotate(0deg);
  	-webkit-transform: rotate(0deg);
}
[dir='rtl'] .slick-next:before {
    content: '';
    transform: rotate(-45deg);
    -webkit-transform: rotate(0deg);
}

/*-----------------
	10. Focus Label
-----------------------*/

.form-focus {
	height: 50px;
	position: relative;
}
.form-focus .focus-label {
	font-size: 14px;
	font-weight: 400;
	pointer-events: none;
	position: absolute;
	-webkit-transform: translate3d(0, 22px, 0) scale(1);
	-ms-transform: translate3d(0, 22px, 0) scale(1);
	-o-transform: translate3d(0, 22px, 0) scale(1);
	transform: translate3d(0, 22px, 0) scale(1);
	transform-origin: left top;
	transition: 240ms;
	left: 12px;
	top: -8px;
	z-index: 1;
	color: #b8b8b8;
	margin-bottom: 0;
}
.form-focus.focused .focus-label {
	opacity: 1;
	top: -18px;
	font-size: 12px;
	z-index: 1;
}
.form-focus .form-control:focus ~ .focus-label, 
.form-focus .form-control:-webkit-autofill ~ .focus-label {
	opacity: 1;
	font-weight: 400;
	top: -18px;
	font-size: 12px;
	z-index: 1;
}
.form-focus .form-control {
	height: 50px;
	padding: 21px 12px 6px;
}
.form-focus .form-control::-webkit-input-placeholder {
	color: transparent;
	transition: 240ms;
}
.form-focus .form-control:focus::-webkit-input-placeholder {
	transition: none;
}
.form-focus.focused .form-control::-webkit-input-placeholder {
	color: #bbb;
}
.form-focus.select-focus .focus-label {
	opacity: 1;
	font-weight: 300;
	top: -20px;
	font-size: 12px;
	z-index: 1;
}
.form-focus .select2-container .select2-selection--single {
	border: 1px solid #e3e3e3;
	height: 50px;
}
.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 48px;
	right: 7px;
}
.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #ccc transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}
.form-focus .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #ccc;
	border-width: 0 6px 6px;
}
.form-focus .select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 12px;
	padding-top: 10px;
}
.form-focus .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #676767;
	font-size: 14px;
	font-weight: normal;
	line-height: 38px;
}
.form-focus .select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #fc6075;
}



/*-----------------
	13. Notifications
-----------------------*/
.notify button, .notify button:hover,  .notify button:active{    background: inherit !important;
    color: #3065b5 !important;
    font-size: 24px !important;
    border: none !important;
}
.notifications {
	padding: 0;
}
.notifications .notification-time {
	font-size: 12px;
	line-height: 1.35;
	color: #bdbdbd;
}
.notifications .media {
	margin-top: 0;
	border-bottom: 1px solid #f5f5f5;
}
.notifications .media:last-child {
	border-bottom: none;
}
.notifications-list .media a {
	display: block;
	padding: 10px 15px;
	border-radius: 2px;
}
.notifications-list .media a:hover {
	background-color: #fafafa;
}
.notifications-list .media > .avatar {
	margin-right: 10px;
}
.notifications-list .media-list .media-left {
	padding-right: 8px;
}
.topnav-dropdown-header {
	border-bottom: 1px solid #eee;
	text-align: center;
}
.topnav-dropdown-header, 
.topnav-dropdown-footer {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
}
.topnav-dropdown-footer {
	border-top: 1px solid #eee;
}
.topnav-dropdown-footer a {
	display: block;
	text-align: center;
	color: #333;
}
.notify .badge {
	background-color:#e97a24;
    display: block;
    font-size: 10px;
    font-weight: bold;
    min-height: 15px;
    min-width: 15px;
    position: absolute;
    right: 3px;
    color: #fff;
    top: 6px;
}
.user-menu.nav > li > a > i {
	font-size: 1.5rem;
	line-height: 60px;
}
.noti-details {
	color: #989c9e;
    margin-bottom: 0;
}
.noti-title {
	color: #333;
}
.notifications .noti-content {
	height: 290px;
	width: 350px;
	overflow-y: auto;
	position: relative;
}
.notification-list {
	list-style: none;
	padding: 0;
	margin: 0;
}
.notifications ul.notification-list > li{
	margin-top: 0;
	border-bottom: 1px solid #f5f5f5;
}
.notifications ul.notification-list > li:last-child {
	border-bottom: none;
}
.notifications ul.notification-list > li a {
	display: block;
	padding: 10px 15px;
	border-radius: 2px;
}
.notifications ul.notification-list > li a:hover {
	background-color: #fafafa;
}
.notifications ul.notification-list > li .list-item {
    border: 0;
    padding: 0;
    position: relative;
}
.topnav-dropdown-header .notification-title {
    color: #333;
    display: block;
    float: left;
    font-size: 14px;
}
.topnav-dropdown-header .clear-noti {
    color: #f83f37;
    float: right;
    font-size: 12px;
    text-transform: uppercase;
}
.noti-time {
	margin: 0;
	text-align: left;
}


/*-----------------
	11. Header
-----------------------*/

.header-nav {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	border: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    position: relative;
	height: 85px;
	padding: 0 30px;
	margin-bottom: 0;
}
.user-header {
	background-color: #fff;
}

.user-main-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
}
.user-main-nav > li.active > a {
	color: #e97a24;
}
.user-main-nav > li .submenu li a {
    display: block;
    padding: 10px 15px;
    clear: both;
    white-space: nowrap;
    font-size: 14px;
    color: #2d3b48;
    -webkit-transition: all .35s ease;
    transition: all .35s ease;
	width: 100%;
	border-top: 1px solid #f0f0f0;
}
.user-main-nav > li .submenu > li.has-submenu > a::after {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 13px;
}
.user-main-nav > li .submenu li {
    position: relative;
}
.user-main-nav li a {
	display: block;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    color: #3065b5;
}
.user-main-nav li.login-link {
	display: none;
}
.user-main-menu-wrapper {
	margin: 0;
    margin-left: auto;
    padding: 0;
}

.notify .badge {
	background-color: #1b5a90;
    display: block;
    font-size: 10px;
    font-weight: bold;
    min-height: 15px;
    min-width: 15px;
    position: absolute;
    right: 3px;
    color: #fff;
    top: 6px;
}





.main-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
}
.main-nav > li.active > a {
	color: #e97a24;
}
.main-nav > li .submenu li a {
    display: block;
    padding: 10px 15px;
    clear: both;
    white-space: nowrap;
    font-size: 14px;
    color: #2d3b48;
    -webkit-transition: all .35s ease;
    transition: all .35s ease;
	width: 100%;
	border-top: 1px solid #f0f0f0;
}
.main-nav > li .submenu > li.has-submenu > a::after {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 13px;
}
.main-nav > li .submenu li {
    position: relative;
}
.main-nav li a {
	display: block;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    color: #3065b5;
}
.main-nav li.login-link {
	display: none;
}
.main-menu-wrapper {
	margin: 0;
    margin-left:auto;
    padding: 0;
}
.logo {
    display: inline-block;
    margin-right: 30px;
	margin-left: 30px;
    width: 160px;
    font-size: 30px;
    font-weight:900;
}
.header-contact-img {
	display: inline-block;
}
.header-contact-img i {
	color: rgba(0, 0, 0, 0.5);
	font-size: 30px;
}
.header-contact-detail {
	display: inline-block;
	padding-left: 10px;
}
.header-contact-detail p.contact-header {
	color: #484848;
	font-size: 13px;
	font-weight: 400;
	margin-bottom: 2px;
	text-align: left;
}
.header-contact-detail p.contact-info-header {
	color: #000;
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 0;
}
.header-navbar-rht {
    margin: 0;
	padding: 0;
	margin-left: 20px;
}
.header-navbar-rht li {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    padding-right: 20px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.header-navbar-rht li:last-child {
	padding-right:0px;
}
.header-navbar-rht li .dropdown-menu {
	border: 0;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}
.header-navbar-rht .dropdown-toggle::after {
	display: none;
}
.header-navbar-rht li .dropdown-menu::before {
	content: "";
	position: absolute;
	top: 2px;
	right: 0;
	border: 7px solid #fff;
	border-color: transparent transparent #ffffff #ffffff;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
	box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
}
.header-navbar-rht li .dropdown-menu .dropdown-item {
	border-top: 1px solid #f0f0f0;
	padding: 10px 15px;
}
.header-navbar-rht li .dropdown-menu .dropdown-item:first-child {
	border-top: 0;
	border-radius: 5px 5px 0 0;
}
.header-navbar-rht li .dropdown-menu .dropdown-item:last-child {
	border-radius: 0 0 5px 5px;
}
.header-navbar-rht li a.header-login:hover {
    background-color: #e97a24;
    border-color: #e97a24;
    color: #fff;
}
.header-navbar-rht li .dropdown-menu a:hover {
    color: #09dca4;
	letter-spacing: 0.5px;
	padding-left: 20px;
	background-color: #fff;
}
.header-navbar-rht li a.header-login {
    border: 2px solid #e97a24;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    color: #e97a24;
    text-transform: uppercase;
    font-weight: 700;
}
.header .has-arrow .dropdown-toggle:after {
	border-top: 0;
	border-left: 0;
	border-bottom: 2px solid #757575;
	border-right: 2px solid #757575;
	content: '';
	height: 8px;
	display: inline-block;
	pointer-events: none;
	-webkit-transform-origin: 66% 66%;
	-ms-transform-origin: 66% 66%;
	transform-origin: 66% 66%;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	width: 8px;
	vertical-align: 2px;
	margin-left: 10px;
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
	-webkit-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
.user-menu {
    float: right;
    margin-right: -10px;
    
    
    position: relative;
    z-index: 99;
}
.user-menu.nav > li > a {
	color: #fff;
	font-size: 14px;
	line-height: 58px;
	padding: 0 15px;
	height: 60px;
	
}
.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
	background-color: rgba(0, 0, 0, 0.2);
}
.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
	color: #fff;
}
.user-img {
    display: inline-block;
    position: relative;
}
.user-img > img {
	height: 31px;
    object-fit: cover;
	width: 31px;
}
.user-menu.nav > li > a.mobile_btn {
	border: 0;
	position: relative;
	padding: 0;
	margin: 0;
	cursor: pointer
}
.notify.dropdown{
	margin-left: -10px;
}
.header-navbar-rht .dropdown-menu {
    min-width: 200px;
    padding: 0;
}
.header-navbar-rht .dropdown-menu .dropdown-item {
    padding: 7px 15px;
}
.header-navbar-rht .dropdown-menu .dropdown-item {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    border-top: 1px solid #e3e3e3;
    padding: 10px 15px;
}
.header-navbar-rht .dropdown-menu .dropdown-item:hover {
    color: #3065b5;
}
.user-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    padding: 10px 15px;
}
.user-header .user-text {
	margin-left: 10px;
}
.user-header .user-text h6 {
	font-size: 15px;
    margin-bottom: 2px;
}
.header-navbar-rht .logged-item .nav-link {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    font-size: 14px;
	line-height: 85px;
	padding: 0 5px;
}
.logged-item .btn-success{background-color:inherit;border:inherit}
.top-header {
	padding: 15px 0;
	background-color: #3065b5;
}
 .fc-button.fc-prev-button, .fc-button.fc-prev-button, .fc-next-button.fc-button{background: #f1f1f1;
    border: none;
	color: #797979;}
	.fc-button {
		background-color: #f1f1f1;
		border-color: none;
		color: #797979;}
.fc-toolbar-title{    font-size: 17px;}
.top-header .container {
	padding: 0 30px;
	max-width: 100%;
}
.top-header .left {
	font-size: 14px;
	color: #fff;
}
.top-header .left ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.top-header ul li {
	font-weight: 600;
	float: left;
	padding-right: 20px;
}
.top-header ul li:last-child {
	padding-right: 0;
}
.top-header ul li i {
	color: #e97a24;
	margin-right: 5px;
}
.top-header .right {
	text-align: right;
	font-size: 14px;
	color: #fff;
	float: right;
}
.top-header .right ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.top-header .right ul li {
	display: inline-block;
	padding: 0 8px;
}
.top-header .right ul li:last-child {
	padding-right: 0;
}
.language-select {
	cursor: pointer;
}
.language-select .dropdown-toggle:after {
	content: "\f078";
	border: 0;
	font-weight: 900;
	font-family: 'Font Awesome\ 5 Free';
	color: #e97a24;
	font-size: 12px;
	position: relative;
	top: 4px;
}


/*-----------------
	12. Mobile Menu
-----------------------*/

.sidebar-overlay {
	background-color: rgba(0, 0, 0, 0.6);
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1040;
}
.menu-opened .main-menu-wrapper {
    transform: translateX(0);
}
.menu-header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
    height: 60px;
    padding:  0 20px;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    border-bottom: 1px solid #f0f0f0;
	display: none;
}
.menu-logo img {
    height: 40px;
}
.menu-close {
	font-size: 18px;
}
.bar-icon {
    display: inline-block;
    width: 31px;
}
.bar-icon span {
    background-color: #e97a24;
    display: block;
    float: left;
    height: 3px;
    margin-bottom: 7px;
    width: 31px;
    border-radius: 2px;
}
.bar-icon span:nth-child(2) {
    width: 16px;
}
.bar-icon span:nth-child(3) {
    margin-bottom: 0;
}
#mobile_btn {
	display: none;
	margin-right: 10px;
}
html.menu-opened body {
    overflow: hidden;
}

/*-----------------
	13. Footer
-----------------------*/

.footer {
    background-color: #204174;
}
.footer .footer-top {
	padding: 60px 0 40px;
}
.footer-title {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
    text-transform: capitalize;
}
.footer .footer-widget.footer-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    outline: none;
}
.footer .footer-widget .footer-logo {
	margin-bottom: 30px;
}
.footer .footer-widget .footer-about-content p {
	color: #fff;
}
.footer .footer-widget .footer-about-content p:last-child {
	margin-bottom: 0;
}
.footer .footer-menu ul li {
    margin-bottom: 10px;
    position: relative;
}
.footer .footer-menu ul li:last-child {
	margin-bottom: 0;
}
.footer .footer-menu ul li a {
	color: #fff;
	font-size: 15px;
	padding-left: 20px;
	position: relative;
	transition: all 0.4s ease 0s;
}
.footer .footer-widget.footer-menu ul li a:hover {
	color: #fff;
	letter-spacing: 0.5px;
	padding-left: 30px;
}
.footer .footer-menu ul li a::before {
    content: "\f101";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    left: 0;
    margin-right: 5px;
    position: absolute;
    top: 0;
}
.footer-contact-info {
	color: #fff;
	font-size: 15px;
}
.footer-contact-info .footer-address {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.footer-contact-info .footer-address span {
	margin-right: 20px;
}
.footer-contact-info .footer-address span i.fa-map-marker-alt {
	font-size: 20px;
}
.footer-contact-info p i {
	margin-right: 15px;
}
.footer .footer-bottom .copyright {
	border-top: 1px solid #5f5f5f;
	padding: 30px 0;
}
.footer-logo {
	text-transform: uppercase;
	color: #e97a24;
}
.footer .media-btn li a {
	border-radius: 15px 5px;
    background-color: #05264f;
    padding: 15px;
}
.footer .footer-bottom .copyright-text p {
	color: #fff;
	font-size: 15px;
}
.footer .footer-bottom .copyright-text p a {
	color: #09e5ab;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}
.footer .footer-bottom .copyright-text p a:hover {
	color: #fff;
}
.footer .footer-bottom .copyright-text p.title {
	font-weight: 400;
	margin: 10px 0 0;
}
.footer .social-icon ul {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	list-style: none;
	padding: 0;
	margin: 0;
}
.footer .social-icon ul li {
	margin-right: 15px;
}
.footer .social-icon ul li:last-child {
	margin-right: 0;
}
.footer .social-icon ul li a {	
	color: #fff;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-size: 20px;
	transition: all 0.4s ease 0s;
}
.footer .social-icon ul li a:hover {
	color: #e97a24;
}
.policy-menu {
    font-size: 14px;
    margin: 0;
    padding: 0;
    text-align: right;
}
.policy-menu li {
    display: inline-block;
    margin-right: 15px;
}
.policy-menu li:last-child {
	margin-right: 0;
}
.policy-menu li a {
    color: #fff;
}
.policy-menu li a:hover, .policy-menu li a:focus {
	color: #e97a24;
}
.policy-menu li::after {
	color: #e97a24;
    content: "|";
    font-weight: 300;
    position: relative;
    left: 10px;
}
.policy-menu li:last-child::after {
    content: "";
}
.footer-bottom .text-yellow {
	color: #e97a24 !important;
}
/*-----------------
	14. Login
-----------------------*/

.account-page {
	background-color: #fff;
}
.account-page .content {
    padding: 50px 0;
}
.login-right {
	background-color: #fff;
	border: 1px solid #f0f0f0;
	border-radius: 4px;
	padding: 25px;
}
.login-header {
	margin-bottom: 20px;
}
.login-header p {
	margin-bottom: 0;
}
.login-header h3 {
    font-size: 18px;
    margin-bottom: 3px;
}
.login-header h3 a {
    color: #3065b5;
    float: right;
    font-size: 15px;
    margin-top: 2px;
}
.login-right .dont-have {
    color: #3d3d3d;
    margin-top: 20px;
    font-size: 13px;
}
.login-right .dont-have a {
	color: #e97a24;
}
.login-btn {
    font-size: 18px;
    font-weight: 500;
}
.login-or {
	color: #d4d4d4;
	margin-bottom: 20px;
	margin-top: 20px;
	padding-bottom: 10px;
	padding-top: 10px;
	position: relative;
}
.or-line {
	background-color: #e5e5e5;
	height: 1px;
	margin-bottom: 0;
	margin-top: 0;
	display: block;
}
.span-or {
	background-color: #fff;
	display: block;
	left: 50%;
	margin-left: -20px;
	position: absolute;
	text-align: center;
	top: -3px;
	width: 42px;
}
.forgot-link {
    color: #3d3d3d;
    display: inline-block;
    font-size: 13px;
    margin-bottom: 10px;
	font-weight:400;
}
.btn-facebook {
    background-color: #3a559f;
    color: #fff;
    font-size: 13px;
    padding: 8px 12px;
}
.btn-google {
    background-color: #dd4b39;
    color: #fff;
    font-size: 13px;
    padding: 8px 12px;
}
.social-login .btn:hover, .social-login .btn:focus {
	color: #fff;
}

/*-----------------
	15. Home
-----------------------*/

.section-search { 
 background: #f9f9f9 url(../images/banner.jpg) no-repeat bottom center !important; 
    min-height: 450px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding: 130px 0 200px;
}
.section-header {
    margin-bottom: 60px;
}
.section-header h2 {
    font-size: 36px;
    font-weight: 500;
}
.section-header .sub-title {
    color: #757575;
    font-size: 16px;
    max-width: 600px;
    margin: 15px auto 0;
}
.section-header p {
	color: #858585;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 15px;
    line-height: 1.5;
    max-width: 1000px;
    margin: 0 auto;
}
.banner-wrapper {
    margin: 0;
    max-width: 700px;
    width: 100%;
}
.banner-wrapper .banner-header {
	margin-bottom: 30px;
}
.banner-wrapper .banner-header h6 {
	color:#775d0d;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 700;
}
.banner-wrapper .banner-header h1 {
    margin-bottom: 10px;
	font-size: 42px;
	color: #242424;
	font-weight: 900;
	max-width: 400px;
}
.banner-wrapper .banner-header p {
	color:#05264f;
	font-size: 18px;
	line-height: 1.3;
}
.btn-yellow {
	background-color: #e97a24;
	color: #ffffff;
	font-size: 18px;
	border-radius: 5px;
	text-align: center;
	display: inline-block;
	padding: 8px 25px;
	font-weight: 700;
}
.btn-yellow:hover {
	opacity: 0.9;
	color: #3065b5;
}
.search-box form {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}	
.search-box .form-control {
    border: 1px solid #ccc;
    box-shadow: inset 0 0px 0px rgba(0,0,0,.075);
    border-radius: 5px;
    padding-left: 35px;
}
.search-box .search-location {
    -ms-flex: 0 0 240px;
    flex: 0 0 240px;
    margin-right: 12px;
    position: relative;
    width: 240px;
}
.search-location .form-control {
	/* background: #fff url(../img/location.png) no-repeat 10px center; */
}
.search-box .search-info {
    -ms-flex: 0 0 490px;
    flex: 0 0 490px;
    margin-right: 12px;
    position: relative;
    width: 490px;
}
.search-info .form-control {
	/* background: #fff url(../img/search.png) no-repeat 10px center; */
}
.search-box .search-btn {
    width: 46px;
    -ms-flex: 0 0 46px;
    flex: 0 0 46px;
    height: 46px;
}
.search-box .search-btn span {
	display: none;
	font-weight: 500;
}
.search-box .form-text {
    color: #757575;
    font-size: 13px;
}
.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 5px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 5px;
    padding: 0;
    cursor: pointer;
    color: transparent;
	border: 0;
	outline: none;
	background: #C0C0C0;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
	display: none;
    font-family: 'slick';
	font-size: 76px;
	line-height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	content: '-';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button {
    background-color: #0de0fe;
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #0de0fe;
}
.slick-slide {
	outline: none !important;
}
.specialities-slider .slick-slide {
    display: block;
    padding: 0 12px;
    margin-left: 0;
    margin-right: 20px;
	margin-top: 10px;
}
.specialities-slider .slick-dots {
    margin-top: 44px;
    position: unset;
}
.speicality-img {
	position: relative;
	height: 150px;
	box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
	border-radius: 100%;
	width: 150px;
	background: #fff;
}
.speicality-img img {
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	right: 0;
	margin: 0 auto;
}
.speicality-img span {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: absolute;
    bottom: 10px;
    right: 10px;
    box-shadow: 1px 6px 14px rgba(0,0,0,0.2);
    border-radius: 50%;
    padding: 5px;
    background-color: #fff;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.speicality-img span i {
    font-size: 15px;
	color:#0de0fe;
}
.speicality-item p {
    font-size: 16px;
    font-weight: 500;
    margin: 30px 0 0;
}
.section-doctor {
	background-color: #f8f9fa;
	padding: 80px 0;
}
.section-doctor .section-header {
	margin-bottom: 30px;
}
.section-doctor .section-header p {
	margin-top: 10px;
}
.doctor-slider .slick-slide{
	display: block;
	margin-left: 0;
	padding: 10px;
	width: 310px;
}
.doctor-slider .slick-prev, .doctor-slider .slick-next{top:inherit}

.doctors-col .profile-widget {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius:4px;
    margin-bottom:30px;
    position:relative;
    -webkit-transition:all .3s ease 0s;
    -moz-transition:all .3s ease 0s;
    -o-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
	padding: 0;
	border-radius: 30px 7px;
}
.doctors-col .doc-img {
    position: relative;
    overflow: hidden;
    z-index: 1;    
    border-bottom: 3px solid #e97a24;    
    border-top-left-radius: 30px;
    border-top-right-radius: 7px;
}
.doctors-col .doc-img img {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    width: 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 7px;
}

.doctors-col .pro-content {
    padding: 15px;
}
.doctors-col .profile-widget .rating {
    color: #858585;
    font-size: 14px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #c4c4c4;
}
.doctors-col .available-info {
	font-size: 14px;
    color: #858585;
    font-weight: 400;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}
.profile-widget {
	background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    margin-bottom: 30px;
    position: relative;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    padding: 15px;
}
.doc-img {
	position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 4px;
}
.doc-img img {
	border-radius: 4px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    width: 100%;
}
.doc-img:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.profile-widget .fav-btn {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #fff;
    width: 30px;
    height: 30px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    border-radius: 3px;
    color: #2E3842;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    opacity: 0;
    visibility: hidden;
	z-index: 99;
}
.profile-widget:hover .fav-btn {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.profile-widget .fav-btn:hover {
	background-color: #fb1612;
	color: #fff;
}

.profile-widget
.profile-widget .blog-btn i {
	color: #e97a24;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}
.pro-content {
    padding: 15px 0 0;
}
.pro-content .title {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;
}
.profile-widget .pro-content .title a {
    display: inline-block;
    color: #3065b5;
    font-size: 20px;
    font-weight: 700;
}
.pro-footer {
	background-color: #e97a24;
	padding: 10px;
	border-bottom-right-radius: 30px;
	border-bottom-left-radius: 7px;
}
.pro-footer .policy-menu li a {
    /* color: #3065b5; */
	color: #ffffff;
    font-weight: 800;
}
.pro-footer .policy-menu li::after {
	/* color: #3065b5; */
	color: #ffffff;
}
.profile-widget .verified {
	color: #28a745;
	margin-left: 3px;
}
.profile-widget p.speciality {
	font-size: 14px;
    color: #858585;
    margin-bottom: 5px;
    min-height: 20px;
}
.rating {
    list-style: none;
    margin: 0 0 7px;
    padding: 0;
    width: 100%;
}
.rating i {
    color: #e97a24;
}
.rating i.filled {
    color: #e97a24;
}
.profile-widget .rating {
	color: #757575;
    font-size: 14px;
    margin-bottom: 15px;
}
.profile-widget .rating i {
	font-size: 14px;
}
.available-info {
    font-size: 13px;
    color: #757575;
    font-weight: 400;
    list-style: none;
    padding: 0;
    margin-bottom: 15px;
}
.available-info li + li {
	margin-top: 5px;
}
.available-info li i {
	width: 22px;
	color: #3065b5;
}
.row.row-sm {
    margin-left: -3px;
    margin-right: -3px;
}
.row.row-sm > div {
    padding-left: 3px;
    padding-right: 3px;
}
.view-btn {
    color: #e97a24;
    font-size: 13px;
    border: 2px solid #e97a24;
    text-align: center;
    display: block;
    font-weight: 500;
	padding: 6px;
}
.view-btn:hover, .view-btn:focus {
	background-color: #e97a24;
	color: #fff;
}
.book-btn {
	background-color: #e97a24;
    border: 2px solid #e97a24;
    color: #fff;
    font-size: 13px;
    text-align: center;
    display: block;
    font-weight: 500;
	padding: 6px;
}
.book-btn:hover, .book-btn:focus {
	background-color: #e97a24;
	border-color: #e97a24;
	color: #fff;
}
.section-doctor .profile-widget {
    box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    border-radius: 30px 7px;
}
.about-content p {
	font-size: 14px;
	font-weight: 400;
	line-height: 26px;
	margin: 0;
}
.about-content p + p {
	margin-top: 20px;
}
.about-content a {
    background-color: #0de0fe;
    border-radius: 4px;
	color: #fff;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-top: 30px;
    min-width: 150px;
    padding: 15px 20px;
    text-align: center;
}
.about-content a:hover, .about-content a:focus {
	background-color: #01cae4;
	border-color: #01cae4;
	color: #fff;
}
.section-features {
	background-color: #fff;
	padding: 80px 0;
}
.feature-item img {
    border-radius: 100%;
    box-shadow: 1px 6px 14px rgba(0,0,0,0.2);
    height: 115px;
    object-fit: cover;
	width: 115px;
}
.feature-item p {
    font-weight: 500;
    margin: 20px 0 0;
}
.features-slider .slick-slide {
	margin-right: 62px;
}
.features-slider .slick-center {
    opacity: 1;
    transform: scale(1.20);
}
.features-slider .slick-list {
    padding: 16px 50px !important
}
.features-slider .slick-dots {
    margin-top: 44px;
    position: unset;
}
.section-blogs {
    background-color: #f8f9fa;
    padding: 80px 0;
}
.view-all .btn {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 30px;
}
.top-service-col{
	background-color: #f5f5f5;
	padding: 0px 0 60px;
	margin-top: -90px;
}
.top-service-col:after {
	content: "";
	display: table;
	clear: both;
}
.top-service-col .service-icon {
	height: 80px;
	width: 80px;
	/* border-radius: 100px;
	border: 4px solid #e97a24; */
	text-align: center;
	margin: 0 auto 15px;
}
.top-service-col .service-icon img {
	max-width: 100%;
	width: 100%;
	/* border-radius: 100px; */
	height: 100%;
}
.service-box {
	position: relative;
}
.service-box .card {
	box-shadow: 4px 0px 70px rgba(0,0,0,0.19);
	height: 250px;
}
.service-box h3 {
	font-weight: 700;
	font-size: 22px;
	color: #3065b5;
}

.service-box p {
	 line-height: 1.3;
	/* color: #373737;  */
	font-size: 14px;
    color: #858585;
    margin-bottom: 5px;
    /* min-height: 20px; */
}
.service-box .service-btn a {
	position: absolute;
	left: 50%;
	transform: translateX(-50%) rotate(-45deg);
	-webkit-transform: translateX(-50%) rotate(-45deg);
	-ms-transform: translateX(-50%) rotate(-45deg);
	bottom: -22px;
	background-color: #3065b5;
	width: 45px;
	height: 45px;
	border-radius: 5px 15px 5px 15px;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
}
.service-box .service-btn i {
	color: #e97a24;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
}
.feature-section {
	padding: 60px 0 140px;
	background-color: #204174;
}
.feature-section .left h6 {
	font-size: 18px;
	text-transform: uppercase;
	color: #e97a24;
	font-weight: 900;
}
.feature-section .left h2 {
	font-size: 36px;
	font-weight: 900;
	color: #fff;
	max-width: 500px;
}
.feature-section .right p {
	color: #fff;
	line-height: 1.5;
}
.feature-column {
	padding: 40px 5px 0;
}
.feature-box {
	width: 20%;
	padding: 30px 10px;
}
.feature-box .inner-feature-box {
	background-color: #3065b5;
	padding: 25px 15px 15px;
	position: relative;
}
.feature-box .feature-icon {
	padding-bottom: 20px;
}
.feature-box h4 {
	font-weight: 900;
	color: #fff;
	font-size: 20px;
	line-height: normal;
	margin-bottom: 15px;
}
.feature-box p {
	color: #fff;
	max-width: 212px;
	margin: 0 auto 15px;
}
.feature-box .feature-btn a {
	position: absolute;
	left: 50%;
	transform: translateX(-50%) rotate(-45deg);
	-webkit-transform: translateX(-50%) rotate(-45deg);
	-ms-transform: translateX(-50%) rotate(-45deg);
	bottom: -20px;
	background-color: #e97a24;
	width: 40px;
	height: 40px;
	border-radius: 5px 15px 5px 15px;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
}
.feature-box .feature-btn i {
	color: #204174;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
}
.location-col {
	margin-top: -82px;
}
.location-col .location-box {
	background-color: #fff;
	padding: 30px 15px;
	border: 3px solid #e97a24;
	box-shadow: 5px 0 38px rgba(0,0,0,0.13);
	border-radius: 50px 7px;
}
.location-col .location-box h4 {
	font-size: 22px;
	color: #204174;
	font-weight: 900;
}
.location-col .location-box p {
	color: #3065b5;
	line-height: 1.5;
	max-width: 510px;
	margin: 0 auto;
}
.location-col .location-btn a {
	position: absolute;
	left: 50%;
	transform: translateX(-50%) rotate(-45deg);
	-webkit-transform: translateX(-50%) rotate(-45deg);
	-ms-transform: translateX(-50%) rotate(-45deg);
	bottom: -20px;
	background-color: #3065b5;
	width: 40px;
	height: 40px;
	border-radius: 5px 15px 5px 15px;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
}
.location-col .location-btn  i {
	color: #e97a24;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
}
.aboutus-col {
	padding: 80px 0 60px;
	background-color: #f5f5f5;
}
.aboutus-col .about-left {

}
.aboutus-col .about-right h6 {
	font-size: 18px;
	text-transform: uppercase;
	color: #e97a24;
	font-weight: 900;
}
.aboutus-col .about-right h2 {
	font-size: 36px;
	font-weight: 900;
	color: #3065b5;
	max-width: 600px;
	padding-bottom: 20px;	
}
.aboutus-col .about-right p {
	line-height: 1.7;
	color: #3065b5;
}	
.testimonial {
	padding: 60px 0 100px;
	background-color: #fff;
}
.testimonial .slick-slide {
	width: 100%;
}
.testimonial .testimonial-item .row > div {
	display: flex;
	display: -webkit-flex;
}
.testimonial .testimonial-item h6 {
	font-size: 18px;
	text-transform: uppercase;
	color: #e97a24;
	font-weight: 900;
}
.testimonial .testimonial-item h2 {
	font-size: 36px;
	font-weight: 900;
	color: #3065b5;
	padding-bottom: 10px;	
}
.testimonial .testimonial-item p {
	line-height: 1.7;
	color: #3065b5;
}
.testimonial .testimonial-profile {
	background-color: #3065b5;
	border-radius: 50px 6px 50px 6px;
	padding: 15px;
	width: 100%;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
}
.testimonial .testimonial-profile > div {
	width: 100%;
}
.testimonial .testimonial-profile .profile-icon {
	width: 120px;
	height: 120px;
	border: 3px solid #e97a24;
	border-radius: 120px;
	margin: 0 auto 15px;
}
.testimonial .testimonial-profile .profile-icon img {
	border-radius: 120px;
	max-width: 100%;
	width: 100%;
}
.testimonial .testimonial-profile h4 {
	color: #fff;
	font-weight: 900;
	font-size: 22px;
	margin-bottom: 0;
}
.testimonial .testimonial-profile span {
	font-size: 16px;
	font-weight: 600;
	color: #e97a24;
}
.testimonial .slick-dots {
	width: auto;
}
.testimonial .slick-dots li {
	display: inherit;
	float: left;
	margin: 0 3px;
	width: auto;
}
.testimonial .slick-dots li button {
	border-radius: 50px;
	height: 10px;
	width: 18px;
}
.testimonial .slick-dots li.slick-active button {
	width: 30px;
	background-color: #e97a24;
}
.count-section {
	 background: #f9f9f9 url(../images/images/count-bg.jpg) no-repeat bottom center;
	padding: 60px 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.count-box {
	padding: 20px;
    border: 1px solid #ffffff;
    border-radius: 30px 7px;
}
.count-box h3 {
	font-size: 30px;
	color: #e97a24;
	font-weight: 900;
}
.count-box p {
	color: #fff;
    max-width: 200px;
    margin: 0 auto 15px;
}
.doctors-col {
	padding: 35px 20px;
}
.dashboard-menu .nav-item{    display: block;
    width: 100%;
	padding: 16px 20px;position:relative}
.map-left .profile-widget{z-index: 0;}	
.doctors-col .doctors-title h6, .section-header h6 {
    font-size: 18px;
    text-transform: uppercase;
    color: #e97a24;
    font-weight: 900;
}
.doctors-col .doctors-title h2, .section-header h2 {
    font-size: 36px;
    font-weight: 900;
    color: #3065b5;
    padding-bottom: 20px;
}
.doctors-col .slick-center {
    opacity: 1;
    transform: none;
}
.doctors-list {
    background-color: #ffffff;
    border-radius: 30px 7px;
}
.appoinment-section {
	background: #f9f9f9 url(../images/appoinment-bg.jpg) no-repeat bottom center; 
	padding: 60px 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.appmt-form label {
	color: #3065b5;
	margin-bottom: 0.25rem;
	font-size: 16px;
	font-weight: 700;
}
.appmt-form input, .appmt-form input:focus  {
	border-color: #e97a24;
}
.appmt-form .card {
	border-radius: .5rem;
	margin-bottom: 0;
}
.appmt-form .card-body {
	padding: 40px;
}
.blog-section {
	padding: 60px 0;
}
.blog-box {
	background-color: #fff;
    border-radius: 30px 7px;
    box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);	
}
.blog-img img {
	border-top-left-radius: 30px;
	border-bottom: 3px solid #e97a24;
	border-top-right-radius: 7px;
    width: 100%;
}
.blog-section .blog-content {
	padding: 15px;
	max-width: 300px;
}
.blog-content span {
	font-size: 14px;
	font-weight: 400;
	color: #e97a24;
}
.blog-content h2 {
	color: #3065b5;
	font-size: 20px;
	font-weight: 700;
}
.blog-section .blog-content p {
	color: #3065b5;
}
.blog-section .blog-content p:last-child {
    margin-bottom: 20px;
}
.blog-box .blog-btn {
	position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
    -webkit-transform: translateX(-50%) rotate(-45deg);
    -ms-transform: translateX(-50%) rotate(-45deg);
    bottom: -20px;
    background-color: #204174;
    width: 40px;
    height: 40px;
    border-radius: 5px 15px 5px 15px;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
}
.blog-box .blog-btn i {
	color: #e97a24;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}
.view-all {
	text-transform: uppercase;
	font-size: 18px;
	color: #e97a24;
	font-weight: 700;
}
.search-holder {
	background-color: #05264f;
	
}
.search-holder input {
	border:none;
	background-color: transparent;
	padding: 10px;
	color: #fff;
	min-width: 500px;
	font-size: 16px;

	
}
.search-holder input:focus {
	outline: none;
	border: none;
}
.search-holder .btn {
	border-radius: 0;
	/* position: absolute;
top: 0;
border-radius: 5px;
right: 0px;
z-index: 15;
border: none;
top: 5px; 
height: 50px; */
}
/*-----------------
	16. Search
-----------------------*/

.breadcrumb-bar {
    background-color: #3065b5;
    padding: 15px 0;
}
.breadcrumb-bar .breadcrumb-title {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    margin: 5px 0 0;
}
.page-breadcrumb ol {
    background-color: transparent;
    font-size: 12px;
    margin-bottom: 0;
    padding: 0;
}
.page-breadcrumb ol li a {
    color: #fff;
}
.page-breadcrumb ol li.active {
    color: #fff;
}
.page-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
	color: #fff;
	font-size: 10px;
}
.sort-by {
    float: right;
}
.sort-title {
    color: #fff;
    font-size: 14px;
    margin-right: 10px;
}
.sortby-fliter {
	display: inline-block;
	width: 120px;
}
.cal-icon {
	position: relative;
	width: 100%;
}
.cal-icon:after {
	color: #979797;
    content: '\f073';
	display: block;
    font-family: "Font Awesome 5 Free";
    font-size: 16px;
    font-weight: 400;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 10px;
}
.custom_check {
	color: #666;
	display: inline-block;
	position: relative;
	font-size: 14px;
	font-size: .9375rem;
	padding-left: 30px;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.custom_check input {
	position: absolute;
	opacity: 0;
	cursor: pointer
}
.custom_check input:checked ~ .checkmark {
	background-color: #fff;
}
.custom_check .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: 1px solid #dcdcdc;
	background-color: #fff;
	border-radius: 3px;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}
.custom_check .checkmark::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    display: none;
    left: 4px;
    top: 0;
    color: #e97a24;
    font-size: 11px;
}
.custom_check input:checked ~ .checkmark:after {
	display: block
}
.custom_radio {
	color: #555;
	display: inline-block;
	position: relative;
	font-size: 14px;
	font-size: 0.9375rem;
	padding-left: 30px;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.radio_input .custom_radio + .custom_radio {
	margin-left: 15px;
}
.custom_radio input {
	position: absolute;
	opacity: 0
}
.custom_radio input:checked ~ .checkmark:after {
	opacity: 1
}
.custom_radio .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%
}
.custom_radio .checkmark:after {
	display: block;
	content: "";
	position: absolute;
	opacity: 0;
	top: 3px;
	left: 3px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: #ff9b44;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}

.filter-widget {
	margin-bottom: 20px;
}
.filter-widget h4 {
	font-size: 1rem;
	margin-bottom: 15px;
}
.filter-widget .custom_check {
	line-height: 18px;
}
.btn-search .btn {
    background-color: #e97a24;
    border: 1px solid #e97a24;
    color: #fff;
    height: 46px;
	font-weight:500;
	font-size:16px;
}
.doctor-widget {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.doc-info-left {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.doc-info-right {
    margin-left: auto;
    -ms-flex: 0 0 200px;
    flex: 0 0 200px;
    max-width: 200px;
}
.doctor-img {
    -ms-flex: 0 0 150px;
    flex: 0 0 150px;
    margin-right: 20px;
    width: 150px;
}
.doctor-img img {
	border-radius: 5px;
}
.doc-department {
    color: #e97a24;
    font-size: 14px;
    margin-bottom: 8px;
}
.doc-department img {
    width: 19px;
    display: inline-block;
    margin-right: 10px;
}
.doc-location {
    color: #757575;
	font-size: 14px;
    margin-bottom: 25px;
}
.doc-location a {
    color: #e97a24;
    font-weight: 500;
}
.doctor-widget .doc-name {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 3px;
}
.doc-speciality {
    font-size: 14px;
    color: #757575;
    margin-bottom: 15px;
}
.doctor-widget .rating i {
	font-size: 14px;
}
.doctor-widget .average-rating {
	font-size: 14px;
	font-weight: 500;
}
.clinic-details {
	margin-bottom: 15px;
}
.clinic-details h5 {
    font-weight: normal;
    color: #757575;
    margin-bottom: 25px;
}
.clinic-details ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.clinic-details ul li {
	display: inline-block;
	padding-right: 5px;
}
.clinic-details ul li:last-child {
	padding-right: 0;
}
.clinic-details ul li a {
	display: inline-block;
}
.clinic-details ul li a img {
	border-radius: 5px;
	width: 40px;
}
.clinic-services {
    color: #272b41;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    font-size: 13px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.clinic-services span {
    border: 1px solid #ccc;
    border-radius: 4px;
    display: inline-block;
    font-size: 12px;
    padding: 3px 10px;
}
.clinic-services span + span {
    margin-left: 5px;
}
.clini-infos {
    margin-bottom: 15px;
}
.clini-infos ul {
	font-size: 14px;
	list-style: none;
	margin: 0;
	padding: 0;
}
.clini-infos ul li {
    display: block;
    line-height: 30px;
    color: #4E4852;
}
.clini-infos ul li i {
    font-size: 15px;
    min-width: 30px;
}
.clinic-booking a + a {
	margin-top: 15px;
}
.clinic-booking a {
    background-color: #fff;
    border: 2px solid #3065b5;
    border-radius: 4px;
    color: #3065b5;
    display: block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 10px 15px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}
.clinic-booking a.view-pro-btn:hover, .clinic-booking a.view-pro-btn:focus {
	background: #3065b5; 
	color: #fff;
}
.clinic-booking a.apt-btn {
    background-color: #3065b5;
    color: #fff;
}
.clinic-booking a.apt-btn:hover, .clinic-booking a.apt-btn:focus {
	background-color: #3065b5;
	border-color: #3065b5;
	color: #fff;
}
.load-more {
	margin-bottom: 30px;
}


.btn-detail{
    background-color:#fff;
    border: 2px solid #3065b5;
    border-radius: 4px;
    color: #3065b5;
    display: block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 10px 15px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

 .btn-detail:hover, .btn-detail:focus {
	background-color: #3065b5;
	border-color: #3065b5;
	color: #fff;
}

.btn-cart{
    background-color: #fff;
    border: 2px solid #e97a24;
    border-radius: 4px;
    color: #e97a24;
    display: block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 10px 15px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

 .btn-cart:hover, .btn-cart:focus {
	background-color: #e97a24;
	border-color: #e97a24;
	color: #fff;
}



/*-----------------
	17. Doctor Profile
-----------------------*/

.clinic-direction {
	color: #757575;
	font-size: 14px;
	margin-bottom: 25px;
}
.clinic-direction a {
    color: #e97a24;
    font-weight: 500;
}
.doctor-action {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.doctor-action a + a {
	margin-left: 8px;
}
.doctor-action .fav-btn:hover {
	background-color: #fb1612;
	border-color: #fb1612;
	color: #fff;
}
.msg-btn:hover, .msg-btn:focus {
	background-color: #09e5ab;
	border-color: #09e5ab;
	color: #fff;
}
.call-btn:hover, .call-btn:focus {
	background-color: #09e5ab;
	border-color: #09e5ab;
	color: #fff;
}
.user-tabs .nav-tabs > a {
	border: 0;
	border-bottom: 3px solid transparent;
    color: #3e3e3e;
    font-weight: 600;
	padding: 20px;
	width: 25%;
    text-align: center;
}
.user-tabs .nav-tabs > a.active, 
.user-tabs .nav-tabs > a.active:hover, 
.user-tabs .nav-tabs > a.active:focus {
	border-bottom-width: 3px;
	color: #e97a24;
	border-bottom:3px solid #e97a24;
	background-color: #fff;
}
.user-tabs .med-records {
	display: inline-block;
	min-width: 130px;
}
.user-tabs .nav-tabs > li > a:hover {
	background-color: unset;
	color: #e97a24;
}
.widget {
	margin-bottom: 30px;
}
.widget-title {
	margin-bottom: 15px;
}
.experience-box {
	position: relative;
}
.experience-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.experience-list::before {
	background: #ddd;
	bottom: 0;
	content: "";
	left: 8px;
	position: absolute;
	top: 8px;
	width: 2px;
}
.experience-list > li {
	position: relative;
}
.experience-list > li:last-child .experience-content {
	margin-bottom: 0;
}
.experience-user .avatar {
	height: 32px;
	line-height: 32px;
	margin: 0;
	width: 32px;
}
.experience-list > li .experience-user {
	background: #fff;
	height: 10px;
	left: 4px;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 4px;
	width: 10px;
}
.experience-list > li .experience-content {
	background-color: #fff;
	margin: 0 0 20px 40px;
	padding: 0;
	position: relative;
}
.experience-list > li .experience-content .timeline-content {
	color: #757575;
}
.experience-list > li .experience-content .timeline-content a.name {
	font-weight: 500;
}
.experience-list > li .time {
	color: #757575;
	display: block;
	font-size: 13px;
}
.before-circle {
    background-color: rgb(243 237 32 / 20%);
    border-radius: 50%;
    height: 12px;
    width: 12px;
    border: 2px solid #f3c42c;
}
.exp-year {
    color: #f3c42c;
    margin-bottom: 2px;
}
.exp-title {
    font-size: 16px;
}
.awards-widget .experience-list > li:last-child p {
	margin-bottom: 0;
}
.service-list {
    margin-bottom: 30px;
}
.service-list:last-child {
	border-bottom: 0;
	margin-bottom: 0;
	padding-bottom: 0;
}
.service-list ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.service-list ul li {
	float: left;
	margin: 6px 0;
	padding-left: 25px;
	position: relative;
	width: 33%;
}
.service-list ul li::before {
    color: #ccc;
    content: '\f30b';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    left: 0;
    position: absolute;
}
.location-list {
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    padding: 20px;
}
.location-list + .location-list {
	margin-top: 20px;
}
.clinic-content .clinic-name {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 3px;
}
.clinic-content .clinic-direction a {
	display: inline-block;
	margin-top: 8px;
}
.timings-days {
    font-weight: bold;
    color: #272b41;
    margin-bottom: 5px;
}
.timings-times span {
	display: block;
}
.location-list .consult-price {
    font-size: 20px;
    font-weight: 500;
    color: #272b41;
}
.review-listing {
    border-bottom: 1px solid #f5f7fc;
    margin-top: 20px;
    padding-bottom: 30px;
}
.review-listing > ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.review-listing > ul li + li {
	margin-top: 20px;
	border-top: 1px dashed #f0f0f0;
	padding-top: 20px;
}
.review-listing > ul li .comment {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    margin-bottom: 30px;
}
.review-listing > ul li .comment:last-child {
	margin-bottom: 0;
}
.review-listing > ul li .comment .comment-body {
    margin-left: 16px;
}
.review-listing > ul li .comment .comment-body .meta-data {
    position: relative;
    margin-bottom: 10px;
}
.review-listing > ul li .comment .comment-body .meta-data span {
    display: block;
    font-size: 16px;
    color: #757575;
}
.review-listing > ul li .comment .comment-body .meta-data span.comment-author {
    font-weight: 600;
    color: #272b41;
    text-transform: capitalize;
}
.review-listing > ul li .comment .comment-body .meta-data span.comment-date {
    font-size: 14px;
}
.review-listing > ul li .comment .comment-body .meta-data .review-count {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    position: absolute;
    top: 3px;
    right: 0;
    width: auto;
}
.review-listing > ul li .comment .comment-body .comment-content {
    color: #757575;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 14px;
}
.review-listing > ul li .comment .comment-body .comment-reply .comment-btn {
    color: #e97a24;
    display: inline-block;
    font-weight: 500;
    font-size: 15px;
}
.review-listing .recommend-btn {
    float: right;
    color: #757575;
    font-size: 14px;
    padding: 5px 0;
	margin-bottom: 0;
}
.review-listing .recommend-btn a {
    border: 1px solid rgba(128,137,150,0.4);
    border-radius: 4px;
	display: inline-block;
    padding: 4px 12px;
    color: #757575;
    margin-left: 3px;
    margin-right: 3px;
    transition: all .3s;
}
.review-listing .recommend-btn a.like-btn:hover {
	background-color: #28a745;
	border: 1px solid #28a745;
	color: #fff;
}
.review-listing .recommend-btn a.dislike-btn:hover {
	background-color: #dc3545;
	border: 1px solid #dc3545;
	color: #fff;
}
.review-listing .recommend-btn a i {
    font-size: 16px;
}
.review-listing > ul li .comments-reply {
    list-style: none;
    margin-left: 65px;
    padding: 0;
}
.recommended {
    color: #28a745;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
}
.all-feedback {
    margin-top: 20px;
}
.star-rating {
    direction: rtl;
}
.star-rating input[type=radio] {
    display: none
}
.star-rating label {
    color: #bbb;
    cursor: pointer;
    font-size: 18px;
    padding: 0;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}
.star-rating label:hover,
.star-rating label:hover ~ label,
.star-rating input[type=radio]:checked ~ label {
    color: #f2b600
}
.terms-accept a {
	color: #e97a24;
	font-weight: 500;
}
.terms-accept label{margin-left: 7px;}
.business-widget {
    background-color: #fcfcfc;
    border: 1px solid #f0f0f0;
    padding: 20px;
    margin-bottom: 0;
}
.listing-day {
	-webkit-box-align: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
    color: #000;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    margin-bottom: 10px;
}
.listing-day:last-child {
	margin-bottom: 0;
}
.listing-day.current {
    border-bottom: 1px solid #ddd;
    padding-bottom: 13px;
    margin-bottom: 13px;
}
.listing-day .day {
	font-weight: 500;
}
.listing-day.current .day {
	font-weight: bold;
}
.listing-day.current .day span {
	display: block;
	font-weight: normal;
}
.time-items {
    color: #757575;
}
.time-items > span {
    display: block;
    text-align: right;
}
.time-items > span.open-status {
	margin-bottom: 3px;
}
.dropzone .dz-preview.dz-error:hover .dz-error-message {
	display: none;
}
/*-----------------
	18. Booking
-----------------------*/

.booking-doc-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.booking-doc-info .booking-doc-img {
    width: 80px;
    margin-right: 15px;
}
.booking-doc-info .booking-doc-img img {
    border-radius: 4px;
    height: 80px;
    width: 80px;
    object-fit: cover;
}
.schedule-widget {
    border-radius: 4px;
    min-height: 100px;
}
.schedule-header h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 3px 0 0;
}
.schedule-header {
    border-bottom: 1px solid #f0f0f0;
    border-radius: 4px 4px 0 0;
    padding: 10px 20px;
}
.day-slot ul {
    float: left;
    list-style: none;
    margin-bottom: 0;
    margin-left: -5px;
    margin-right: -5px;
    padding: 0;
    position: relative;
    width: 100%;
}
.day-slot li {
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    width: 14.28%;
}
.day-slot li span {
    display: block;
    font-size: 18px;
    text-transform: uppercase;
}
.day-slot li span.slot-date {
    display: block;
    color: #757575;
    font-size: 14px;
}
.day-slot li small.slot-year {
    color: #757575;
    font-size: 14px;
}
.day-slot li.left-arrow {
    left: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 20px !important;
    transform: translateY(-50%);
}
.day-slot li.right-arrow {
    right: -11px;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 20px !important;
    transform: translateY(-50%);
}
.schedule-cont {
    padding: 20px;
}
.time-slot ul {
    list-style: none;
    margin-right: -5px;
    margin-left: -5px;
	margin-bottom: 0;
    padding: 0;
}
.time-slot li {
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    width: 14.28%;
}
.time-slot li .timing {
    background-color: #1db9aa;
    border: 1px solid #1db9aa;
    border-radius: 3px;
    color: #fff;
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    padding: 5px 5px;
    text-align: center;
	position: relative;
}
.time-slot li .timing:hover {
    background-color: #fff;
	color: #757575;
	border: 1px solid #e9e9e9;
}
.time-slot li .timing:last-child {
	margin-bottom: 0;
}
.time-slot li .timing.selected {
	background-color: #3065b5;
	border: 1px solid #3065b5;
	color: #fff;
}
.time-slot li .timing.elapsed {
	background-color: #d1d1d1;
	border: 1px solid #d1d1d1;
	color: #999999;
}
.time-slot li .timing.selected::before {
    color: #fff;
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
	font-size: 12px;
    font-weight: 900;
    position: absolute;
    right: 6px;
    top: 6px;
}
.schedule-list {
    border-bottom: 1px solid #cfcfcf;
    margin-bottom: 50px;
    padding-bottom: 50px;
}
.schedule-list:last-child {
    border-bottom: 0;
    margin-bottom: 0;
	padding-bottom: 0;
}
.submit-section.proceed-btn {
	margin: 0 0 30px;
}

/*-----------------
	19. Checkout
-----------------------*/

.info-widget {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.card-label > label {
    background-color: #fff;
    color: #959595;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    margin: 6px auto auto 8px;
    padding: 0 7px;
}
.card-label > input {
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .05);
    display: block;
    height: 50px;
    margin-top: -13px;
    padding: 5px 15px 0;
    transition: border-color .3s;
    width: 100%;
}
.exist-customer a {
	color: #e97a24;
	font-weight: 500;
}
.payment-widget .payment-list + .payment-list {
	margin-bottom: 15px;
}
.payment-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 600;
    color: #272b41;
    text-transform: capitalize;
}
.payment-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.payment-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 19px;
    height: 19px;
    margin: 3px 0 0 0;
    border: 2px solid #ddd;
        border-top-color: rgb(221, 221, 221);
        border-right-color: rgb(221, 221, 221);
        border-bottom-color: rgb(221, 221, 221);
        border-left-color: rgb(221, 221, 221);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.payment-radio input:checked ~ .checkmark {
    border-color: #e97a24;
}
.payment-radio .checkmark::after {
    position: absolute;
    left: 3px;
    top: 3px;
    content: '';
    width: 9px;
    height: 9px;
    background-color: #e97a24;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -o-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.payment-radio input:checked ~ .checkmark::after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}
.booking-date {
    padding: 0;
    list-style: none;
}
.booking-date li {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: #272b41;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.booking-date li span {
    float: right;
    color: #757575;
    font-weight: 400;
    font-size: 15px;
}
.booking-fee {
    padding: 0;
    list-style: none;
}
.booking-fee li {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: #272b41;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.booking-fee li span {
    float: right;
    color: #757575;
    font-weight: 400;
    font-size: 15px;
}
.booking-total {
    /* border-top: 1px solid #e4e4e4; */
    margin-top: 20px;
    padding-top: 20px;
}
.booking-total ul {
	padding: 0;
	list-style: none;
	margin: 0;
}
.booking-total ul li span {
    font-size: 18px;
    font-weight: 600;
    color: #272b41;
}
.booking-total ul li .total-cost {
    color: #e97a24;
    font-size: 16px;
    float: right;
}

/*-----------------
	20. Booking Success
-----------------------*/

.success-page-cont {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display:flex;
}
.success-card .card-body {
	padding: 50px 20px;
}
.success-cont {
    text-align: center;
}
.success-cont i {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    color: #fff;
    width: 60px;
    height: 60px;
    border: 2px solid #e97a24;
    border-radius: 50%;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    font-size: 30px;
    margin-bottom: 30px;
    background-color: #e97a24;
}
.success-cont h3 {
    font-size: 24px;
}
.success-cont p {
    margin-bottom: 30px;
}
.success-cont strong {
    font-weight: 600;
}
.view-inv-btn {
	font-size: 16px;
	font-weight: 600;
	padding: 12px 30px;
}

/*-----------------
	21. Invoice View
-----------------------*/

.invoice-content {
    background-color: #fff;
	border: 1px solid #f0f0f0;
    border-radius: 4px;
    margin-bottom: 30px;
	padding: 30px;
}
.invoice-item .invoice-logo {
    margin-bottom: 30px;
}
.invoice-item .invoice-logo img {
    width: auto;
    max-height: 52px;
}
.invoice-item .invoice-text h2 {
	color:#272b41;
	font-size:36px;
	font-weight:600;
}
.invoice-item .invoice-details {
	text-align:right;
	color:#757575;
	font-weight:500
}
.invoice-item .invoice-details strong {
	color:#272b41
}
.invoice-item .invoice-details-two {
	text-align:left
}
.invoice-item .invoice-text {
	padding-top:42px;
	padding-bottom:36px
}
.invoice-item .invoice-text h2 {
	font-weight:400
}
.invoice-info {
	margin-bottom: 30px;
}
.invoice-info p {
	margin-bottom: 0;
}
.invoice-info.invoice-info2 {
    text-align: right;
}
.invoice-item .customer-text {
	font-size: 18px;
	color: #272b41;
	font-weight: 600;
	margin-bottom: 8px;
	display: block
}
.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
	color: #272b41;
	font-weight: 600;
	padding: 10px 20px;
	line-height: inherit
}
.invoice-table tr td,
.invoice-table-two tr td {
	color: #757575;
	font-weight: 500;
}
.invoice-table-two {
	margin-bottom:0
}
.invoice-table-two tr th,
.invoice-table-two tr td {
	border-top: 0;
}
.invoice-table-two tr td {
	text-align: right
}
.invoice-info h5 {
    font-size: 16px;
    font-weight: 500;
}
.other-info {
    margin-top: 10px;
}

/*-----------------
	22. Schedule Timings
-----------------------*/

.tab-content.schedule-cont .card-title {
	margin-bottom: 10px;
}
.doc-times {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.doc-slot-list {
	background-color: #d9534f;
	border: 1px solid #d43f3a;
	border-radius: 4px;
	color: #fff;
	font-size: 14px;
	margin: 10px 10px 0 0;
	padding: 6px 15px;
}
.doc-slot-list a {
	color: #e48784;
	display: inline-block;
	margin-left: 5px;
}
.doc-slot-list a:hover {
	color: #fff;
}
.schedule-nav .nav-tabs {
	border-bottom:none;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.schedule-header .schedule-nav .nav-tabs .nav-link{    border: 1px solid #dcddea;
    border-radius: 4px;
    padding: 6px 15px;
    text-transform: uppercase;
    font-size: 14px;
	margin: 0px 5px;}
	.schedule-header .schedule-nav .nav-tabs .nav-link.active{     color: #fff;
		background-color:#ff4877;
	
		border: 1px solid #ff4877;}

.schedule-nav .nav-tabs li {
	margin: 5px 15px 5px 0; 
	display: inline-block;
}
.schedule-nav .nav-tabs li:last-child {
	margin-right: 0;
}
.schedule-nav .nav-tabs > li > a {
	border: 1px solid #dcddea;
	border-radius: 4px;
	padding: 6px 15px;
	text-transform: uppercase;
	font-size: 14px;
}
.schedule-nav .nav-tabs li a.active {
	background: #ff4877;
    border: 1px solid #ff4877 !important;
	color: #fff;
}
.hours-info .form-control {
	min-height: auto;
}
.doc-img:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.hours-info .btn.btn-danger.trash {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    height: 38px;
    width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	padding: 0px 30px;
}
.edit-link {
    color: #e97a24;
    font-size: 16px;
    margin-top: 4px;
}

/*-----------------
	23. Doctor Dashboard
-----------------------*/

.dash-widget {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.circle-bar {
    margin-right: 15px;
}
.dct-border-rht {
	border-right: 1px solid #f0f0f0;
}
.dash-widget h6 {
    font-size: 16px;
    font-weight: 400;
}
.dash-widget h3 {
    font-size: 24px;
    margin-bottom: 5px;
}
.dash-widget p {
	color: #757575;
    font-size: 14px;
	margin-bottom: 0;
}
.circle-bar > div {
	display: inline-block;
	position: relative;
	text-align: center;
}
.circle-bar > div img {
    left: 0;
    position: absolute;
    top: 50%;
    right: 0;
    text-align: center;
    margin: 0 auto;
    transform: translateY(-50%);
}
.circle-bar > div canvas {
	width: 90px !important;
	height: 90px !important;
}
.dash-card .row {
	margin-left: -10px;
	margin-right: -10px;
}
.dash-card .row > div {
	padding-left: 10px;
	padding-right: 10px;
}
.appointment-tab {
	margin-bottom: 30px;
}

.appointment-tab .tab-view.nav{    background-color: #fff;
    padding: 20px;
    border: 1px solid #f0f0f0;
    border-radius: .25rem 0.25rem 0 0 !important;
    border-bottom: 0;
    margin-bottom: 0px;}
.table-action .btn-sm{
    margin-right: 3px;
}
.RCP svg {
    width: 100px;
    position: relative;
    height: 100px;
}
.RCP {
    margin-right: 5px;
    width: 105px !important;
}

.appointment-tab .tab-view {margin-bottom: 15px;}
.appointment-tab .nav-tabs a.active {
    background: #3065b5;
	color: #ffffff;
    border-bottom: 3px solid #3065b5;
    padding: 0px;
    border-radius: 30px;
    /* padding: 5px; */
    width: 130px;
    height: 45px;
    line-height: 45px;
    font-weight: normal;
}
.appointment-tab .nav-tabs a {
    width: 130px;
    height: 45px;
    line-height: 45px;
    padding: 0px;
	font-weight: normal;
	text-align: center;
    color: #000;
}
.appointment-tab .tab-content {
	padding-top: 0;
}
.appointment-tab .card {
	border-radius: 0;
}
.submit-btn-bottom {
	margin-bottom: 30px;
}

/*-----------------
	24. Patient Profile
-----------------------*/

.add-new-btn {
    background-color: #3065b5;
    border-radius: 30px;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 10px 20px;
}
.add-new-btn:focus, .add-new-btn:hover,.add-new-btn:active {
	background-color: #3065b5;
    color: #fff;
}
.patient-info {
	margin-top: 15px;
}
.patient-info ul {
    padding: 0;
    list-style: none;
	font-size: .875rem;
	margin: 0;
}
.patient-info ul li {
    position: relative;
    font-size: .875rem;
    font-weight: 500;
    color: #272b41;
    text-transform: capitalize;
}
.patient-info ul li + li {
	margin-top: 15px;
}
.patient-info ul li i {
	width: 18px;
}
.patient-info ul li span {
    color: #757575;
    float: right;
    font-weight: 400;
}

/*-----------------
	25. Add Billing
-----------------------*/

.biller-info, .billing-info {
	margin-bottom: 15px;
}
.add-more-item {
	margin-bottom: 10px;
}
.add-more-item a {
	color: #e97a24;
	font-weight: 500;
}
.signature-wrap {
    float: right;
    margin-bottom: 20px;
    text-align: center;
    width: 220px;
}
.signature {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    border: 2px dashed #ccc;
    border-radius: 4px;
	color: #272b41;
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    height: 85px;
	margin-bottom: 15px;
    width: 100%;
}
.signature:hover {
    background-color: #fcfcfc;
}
.sign-name {
	width:100%;
	float:right;
}
.pat-widget-profile .pro-widget-content {
	padding: 0 0 20px;
}
.pat-widget-profile .booking-date li {
	font-size: .875rem;
}
.pat-widget-profile .booking-date li span {
    font-size: .875rem;
}

/*-----------------
	26. Chat
-----------------------*/

.chat-page .footer {
	display: none;
}
.chat-window {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
}
.chat-cont-left {
    border-right: 1px solid #f0f0f0;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    left: 0;
    max-width: 35%;
    position: relative;
    z-index: 4;
}
.chat-cont-left .chat-header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
    color: #272b41;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    height: 72px;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    padding: 0 15px;
}
.chat-cont-left .chat-header span {
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
}
.chat-cont-left .chat-header .chat-compose {
    color: #8a8a8a;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.chat-cont-left .chat-search {
    background-color: #f5f5f6;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 15px;
    width: 100%;
}
.chat-cont-left .chat-search .input-group {
    width: 100%;
}
.chat-cont-left .chat-search .input-group .form-control {
    background-color: #fff;
    border-radius: 50px;
    padding-left: 36px;
}
.chat-cont-left .chat-search .input-group .form-control:focus {
    border-color: #ccc;
    box-shadow: none;
}
.chat-cont-left .chat-search .input-group .input-group-prepend {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    bottom: 0;
    color: #666;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    left: 15px;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 4;
}
.chat-window .chat-scroll {
	min-height: 300px;
    max-height: calc(100vh - 224px);
    overflow-y: auto;
}
.chat-cont-left .chat-users-list {
    background-color: #fff;
}
.chat-cont-left .chat-users-list a.media {
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 15px;
    transition: all 0.2s ease 0s;
}
.chat-cont-left .chat-users-list a.media:last-child {
	border-bottom: 0;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap {
    margin-right: 15px;
    position: relative;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
    height: 45px;
    width: 45px;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap .status {
	bottom: 7px;
	height: 10px;
	right: 4px;
	position: absolute;
	width: 10px;
	border: 2px solid #fff;
}
.chat-cont-left .chat-users-list a.media .media-body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name {
    color: #272b41;
    text-transform: capitalize;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    color: #8a8a8a;
    font-size: 14px;
    line-height: 24px;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child {
    text-align: right;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child .last-chat-time {
    color: #8a8a8a;
    font-size: 13px;
}
.chat-cont-left .chat-users-list a.media:hover {
	background-color: #f5f5f6;
}
.chat-cont-left .chat-users-list a.media.read-chat .media-body > div:last-child .last-chat-time {
	color: #8a8a8a;
}
.chat-cont-left .chat-users-list a.media.active {
	background-color: #f5f5f6;
}
.chat-cont-right {
    /* -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%; */
	-ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
.chat-cont-right .chat-header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    height: 72px;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    padding: 0 15px;
}
.chat-cont-right .chat-header .back-user-list {
    display: none;
    margin-right: 5px;
    margin-left: -7px;
}
.chat-cont-right .chat-header .media {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.chat-cont-right .chat-header .media .media-img-wrap {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 15px;
}
.chat-cont-right .chat-header .media .media-img-wrap .avatar {
	height: 50px;
	width: 50px;
}
.chat-cont-right .chat-header .media .media-img-wrap .status {
    border: 2px solid #fff;
    bottom: 0;
    height: 10px;
    position: absolute;
    right: 3px;
    width: 10px;
}
.chat-cont-right .chat-header .media .media-body .user-name {
    color: #272b41;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
}
.chat-cont-right .chat-header .media .media-body .user-status {
    color: #666;
    font-size: 14px;
}
.chat-cont-right .chat-header .chat-options {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.chat-cont-right .chat-header .chat-options > a {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    border-radius: 50%;
    color: #8a8a8a;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    height: 30px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    margin-left: 10px;
    width: 30px;
}
.chat-cont-right .chat-body {
    background-color: #f5f5f6;
}
.chat-cont-right .chat-body ul.list-unstyled {
    margin: 0 auto;
    padding: 15px;
    width: 100%;
}
.chat-cont-right .chat-body .media .avatar {
    height: 30px;
    width: 30px;
}
.chat-cont-right .chat-body .media .media-body {
	margin-left: 20px;
}
.chat-cont-right .chat-body .media .media-body .msg-box > div {
	padding: 10px 15px;
	border-radius: .25rem;
	display: inline-block;
	position: relative;
}
.chat-cont-right .chat-body .media .media-body .msg-box > div p {
    color: #272b41;
    margin-bottom: 0;
}
.chat-cont-right .chat-body .media .media-body .msg-box + .msg-box {
	margin-top: 5px;
}
.chat-cont-right .chat-body .media.received {
	margin-bottom: 20px;
}
.chat-cont-right .chat-body .media:last-child {
	margin-bottom: 0;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box > div {
	background-color: #fff;
}
.chat-cont-right .chat-body .media.sent {
    margin-bottom: 20px;
}
.chat-cont-right .chat-body .media.sent .media-body {
	-webkit-box-align: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	justify-content: flex-end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: flex-end;
    margin-left: 0;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div {
    background-color: #e3e3e3;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div p {
    color: #272b41;
}
.chat-cont-right .chat-body .chat-date {
    font-size: 14px;
    margin: 1.875rem 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: capitalize;
}
.chat-cont-right .chat-body .chat-date:before {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    margin-right: 28px;
    position: absolute;
    right: 50%;
    top: 50%;
    width: 100%;
}
.chat-cont-right .chat-body .chat-date:after {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    left: 50%;
    margin-left: 28px;
    position: absolute;
    top: 50%;
    width: 100%;
}
.chat-cont-right .chat-footer {
    background-color: #fff;
    border-top: 1px solid #f0f0f0;
    padding: 10px 15px;
    position: relative;
}
.chat-cont-right .chat-footer .input-group {
    width: 100%;
}
.chat-cont-right .chat-footer .input-group .form-control {
    background-color: #f5f5f6;
    border: none;
    border-radius: 50px;
}
.chat-cont-right .chat-footer .input-group .form-control:focus {
    background-color: #f5f5f6;
    border: none;
    box-shadow: none;
}
.chat-cont-right .chat-footer .input-group .input-group-prepend .btn, 
.chat-cont-right .chat-footer .input-group .input-group-append .btn {
    background-color: transparent;
    border: none;
    color: #9f9f9f;
}
.chat-cont-right .chat-footer .input-group .input-group-append .btn.msg-send-btn {
    background-color: #0de0fe;
    border-color: #0de0fe;
    border-radius: 50%;
    color: #fff;
    margin-left: 10px;
    min-width: 46px;
    font-size: 20px;
}
.msg-typing {
	width: auto;
	height: 24px;
	padding-top: 8px
}
.msg-typing span {
	height: 8px;
	width: 8px;
	float: left;
	margin: 0 1px;
	background-color: #a0a0a0;
	display: block;
	border-radius: 50%;
	opacity: .4
}
.msg-typing span:nth-of-type(1) {
	animation: 1s blink infinite .33333s
}
.msg-typing span:nth-of-type(2) {
	animation: 1s blink infinite .66666s
}
.msg-typing span:nth-of-type(3) {
	animation: 1s blink infinite .99999s
}
.chat-cont-right .chat-body .media.received .media-body .msg-box {
	position: relative;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box:first-child:before {
    border-bottom: 6px solid transparent;
    border-right: 6px solid #fff;
    border-top: 6px solid transparent;
    content: "";
    height: 0;
    left: -6px;
    position: absolute;
    right: auto;
    top: 8px;
    width: 0;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box {
    padding-left: 50px;
    position: relative;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box:first-child:before {
	border-bottom: 6px solid transparent;
	border-left: 6px solid #e3e3e3;
	border-top: 6px solid transparent;
    content: "";
    height: 0;
    left: auto;
    position: absolute;
    right: -6px;
    top: 8px;
    width: 0;
}
.chat-msg-info {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    clear: both;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 5px 0 0;
}
.chat-msg-info li {
    font-size: 13px;
    padding-right: 16px;
    position: relative;
}
.chat-msg-info li:not(:last-child):after {
	position: absolute;
	right: 8px;
	top: 50%;
	content: '';
	height: 4px;
	width: 4px;
	background: #d2dde9;
	border-radius: 50%;
	transform: translate(50%, -50%)
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box .chat-msg-info li:not(:last-child)::after {
    right: auto;
    left: 8px;
    transform: translate(-50%, -50%);
    background: #aaa;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box > div .chat-time {
    color: rgba(50, 65, 72, 0.4);
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-time {
    color: rgba(50, 65, 72, 0.4);
}
.chat-msg-info li a {
	color: #777;
}
.chat-msg-info li a:hover {
	color: #2c80ff
}
.chat-seen i {
	color: #00d285;
	font-size: 16px;
}
.chat-msg-attachments {
	padding: 4px 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin: 0 -1px
}
.chat-msg-attachments > div {
	margin: 0 1px
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info {
    flex-direction: row-reverse;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-attachments {
	flex-direction: row-reverse
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info li {
    padding-left: 16px;
    padding-right: 0;
    position: relative;
}
.chat-attachment img {
    max-width: 100%;
}
.chat-attachment {
	position: relative;
	max-width: 130px;
	overflow: hidden;
}
.chat-attachment {
	border-radius: .25rem;
}
.chat-attachment:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #000;
	content: "";
	opacity: 0.4;
	transition: all .4s;
}
.chat-attachment:hover:before {
	opacity: 0.6;
}
.chat-attach-caption {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	color: #fff;
	padding: 7px 15px;
	font-size: 13px;
	opacity: 1;
	transition: all .4s;
}
.chat-attach-download {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: all .4s;
	color: #fff;
	width: 32px;
	line-height: 32px;
	background: rgba(255, 255, 255, 0.2);
	text-align: center;
}
.chat-attach-download:hover {
	color: #495463;
	background: #fff;
}
.chat-attachment:hover .chat-attach-caption {
	opacity: 0;
}
.chat-attachment:hover .chat-attach-download {
	opacity: 1;
}
.chat-attachment-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: -5px;
}
.chat-attachment-list li {
	width: 33.33%;
	padding: 5px;
}
.chat-attachment-item {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border: 5px solid rgba(230, 239, 251, 0.5);
	height: 100%;
	min-height: 60px;
	text-align: center;
	font-size: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div:hover .chat-msg-actions {
	opacity: 1;
}
.chat-msg-actions {
	position: absolute;
	left: -30px;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0;
	transition: all .4s;
	z-index: 2;
}
.chat-msg-actions > a {
    padding: 0 10px;
    color: #495463;
    font-size: 24px;
}
.chat-msg-actions > a:hover {
	color: #2c80ff;
}

@keyframes blink {
	50% {
		opacity: 1
	}
}
.btn-file {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    font-size: 20px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    overflow: hidden;
    padding: 0 0.75rem;
    position: relative;
    vertical-align: middle;
}
.btn-file input {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 100%;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

/*-----------------
	27. Doctor Profile Settings
-----------------------*/
.card .card-body .card-title{    font-weight: 600;}
.ReactTags__tags span {
    color: #fff;
    opacity: 1;
    cursor: move;
    background: #3065b5;
    padding: 6px;
    margin: 5px;
}
.ReactTags__tagInputField:focus{outline:none}
.ReactTags__tagInput{    border: 1px solid #bfbfbf;
    margin-top: 10px;}
.ReactTags__tagInputField{    width: 100%;
    border: none;
    height: 40px;}
.ReactTags__remove{    border: none;
    background: #3065b5}
.profile-image img {
	margin-bottom: 1.5rem;
}
.change-photo-btn {
    background-color: #3065b5;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 13px;
    font-weight: 600;
    margin: 0 auto;
    padding: 10px 15px;
    position: relative;
    transition: .3s;
    text-align: center;
    width: 220px;
}
.change-photo-btn input.upload {
	bottom: 0;
    cursor: pointer;
    filter: alpha(opacity=0);
	left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
	width: 220px;
}
.dropzone {
    background-color: #fbfbfb;
    border: 2px dashed rgba(0, 0, 0, 0.1);
}
.btn-icon {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    text-align: center;
    position: absolute;
    padding: 0;
    font-size: 10px;
    width: 20px;
    height: 20px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    right: 3px;
    top: 3px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.upload-images {
    position: relative;
    width: 80px;
}
.upload-images img {
	border-radius: 4px;
	height: 80px;
	width: auto;
}
.upload-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.upload-wrap .upload-images + .upload-images {
	margin-left: 20px;
}
.contact-card .card-body {
	padding-bottom: 0.625rem;
}
.custom_price_cont {
	margin-top: 20px;
}
.btn.btn-danger.trash {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    height: 46px;
    width: 46px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.add-more a {
	color: #e97a24;
}
.bootstrap-tagsinput {
    border-color: #dcdcdc;
    box-shadow: inherit;
    min-height: 46px;
    width: 100%;
    border-radius: 0;
}
.bootstrap-tagsinput.focus {
	border-color: #bbb;
}
.bootstrap-tagsinput .tag {
    background-color: #20c0f3;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    margin-right: 2px;
    padding: 11px 15px;
    border-radius: 0;
}
.services-card .bootstrap-tagsinput input {
	width: 160px;
}
.submit-section .submit-btn {
    padding: 12px 30px;
    font-weight: 600;
    font-size: 16px;
    min-width: 120px;
}
.submit-section .submit-btn + .submit-btn {
    margin-left: 15px;
}

/*-----------------
	28. Calendar
-----------------------*/

#calendar-events {
    background-color: #fcfcfc;
}
.calendar-events {
    border: 1px solid transparent;
    cursor: move;
    padding: 10px 15px;
}
.calendar-events:hover {
	border-color: #e9e9e9;
    background-color: #fff;
}
.calendar-events i {
    margin-right: 8px;
}
.calendar {
	float: left;
	margin-bottom: 0;
}
.fc-toolbar.fc-header-toolbar {
    margin-bottom: 1.5rem;
}
.none-border .modal-footer {
	border-top: none;
}
.fc-toolbar h2 {
	font-size: 18px !important;
	font-weight: 600;
	font-family: 'Roboto', sans-serif;
	line-height: 30px;
	text-transform: uppercase;
}
.fc-media-screen .fc-button-active{    color: #fff !important;
    text-transform: capitalize;}
.fc-day-grid-event .fc-time {
	font-family: 'Roboto', sans-serif;
}
.fc-day {
	background: #fff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
	z-index: 0;
}
.fc th.fc-widget-header {
	background: #eeeeee;
	font-size: 14px;
	line-height: 20px;
	padding: 10px 0;
	text-transform: uppercase;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
	border-color: #f3f3f3;
}
.fc-basic-view .fc-day-number, 
.fc-basic-view .fc-week-number {
    padding: 2px 5px;
}
.fc-button.fc-button-active{background-color: #3065b5 !important;}
.fc-button {
	background-color: #f1f1f1 !important;
	border: #f1f1f1 !important;
	color: #585858 !important;
	text-transform: capitalize;
	box-shadow: none !important;
	border-radius: 3px !important;
	margin: 0 3px !important;
	padding: 3px 12px !important;
	height: auto !important;
}
.fc-text-arrow {
	font-family: inherit;
	font-size: 16px;
}
.fc-state-hover {
	background: #f3f3f3;
}
.fc-state-highlight {
	background: #f0f0f0;
}
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	background-color: #3065b5 !important;
	color: #fff !important;
	text-shadow: none !important;
}
.fc-cell-overlay {
	background: #f0f0f0;
}
.fc-unthemed .fc-today {
	background: #fff;
}
.fc-event {
	border-radius: 2px;
	border: none;
	color: #fff !important;
	cursor: move;
	font-size: 13px;
	margin: 1px 7px;
	padding: 5px 5px;
	text-align: center;
}
.fc-basic-view td.fc-week-number span {
	padding-right: 8px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
}
.fc-basic-view td.fc-day-number {
	padding-right: 8px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
}
.event-form .input-group .form-control {
	height: 40px;
}

/*-----------------
	29. Patient Dashboard
-----------------------*/

.profile-sidebar {
    background-color: #fff;
	border: 1px solid #f0f0f0;
    border-radius: 4px;
	margin-bottom:30px;
	overflow: hidden;
}
.pro-widget-content {
    border-bottom: 1px solid #f0f0f0;
    padding: 20px;
    text-align: center;
}

.profile-info-widget {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    text-align: left;
}
.profile-info-widget .booking-doc-img {
    margin-right: 15px;
}
.profile-info-widget .booking-doc-img img {
    border-radius: 4px;
    height: 90px;
    width: 90px;
    object-fit: cover;
}
.profile-det-info {
	overflow: hidden;
}
.profile-det-info h3 {
	font-size: 17px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.patient-details h5 {
    color: #757575;
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.patient-details h5 i {
	width: 18px;
}
.dashboard-menu ul {
    color: #757575;
    font-size: 15px;
    line-height: 17px;
    list-style: none;
    margin: 0;
    padding: 0;
    text-transform: capitalize;
}
.dashboard-menu ul li {
	line-height: inherit;
}
.dashboard-menu > ul > li {
	border-bottom:1px solid #f0f0f0;
	position: relative;
}
.dashboard-menu > ul > li:last-child {
	border-bottom: 0;
}
.dashboard-menu ul li a span,
.dashboard-menu ul li a i, .dashboard-menu .nav-item a span {
	display: inline-block;
	vertical-align: middle;
}

.indicator-volume{    position: absolute;
    top: 30px;
    margin-top: 0px;
    left: 35px;
    width: 28px;}
.dashboard-menu > ul > li > a {
	color: #757575;
	display: block;
	padding: 16px 20px;
}
.dashboard-menu > ul > li:hover > a, .dashboard-menu .nav-item .active, .dashboard-menu .nav-item:hover a,
.dashboard-menu > ul > li.active > a{
	color:#e97a24;
}
.dashboard-menu ul li a , .dashboard-menu .nav-item a i, .dashboard-menu .nav-item a {
    font-size: 16px;
    margin-right: 10px;
	/* color:#e97a24; */
    /* width: 5px; */
}
.dashboard-menu-span {
	margin-left: 5px;
	
}
.unread-msg {
    background-color: #d8f111;
    border-radius: 2px;
    color: #272b41;
    font-size: 10px;
    font-style: normal;
    padding: 0 5px;
    position: absolute;
    right: 20px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
}

/*-----------------
	30. Profile Settings
-----------------------*/

.change-avatar {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.change-avatar .profile-img {
	margin-right: 15px;
}
.change-avatar .profile-img img {
    border-radius: 4px;
    height: 100px;
    width: 100px;
    object-fit: cover;
}
.change-avatar .change-photo-btn {
    margin: 0 0 10px;
    width: 150px;
}
.widget-profile.pat-widget-profile .profile-info-widget .booking-doc-img {
	padding: 0;
}
.widget-profile.pat-widget-profile .profile-info-widget .booking-doc-img img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
}

/*-----------------
	31. Appoitment List
-----------------------*/

.widget-profile {
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
}
.widget-profile .profile-info-widget {
	display: block;
	text-align: center;
}
.widget-profile .profile-info-widget .booking-doc-img {
    display: inline-block;
    margin: 0 0 15px;
    width: auto;
    padding: 8px;
    background-color: #f7f7f7;
    border-radius: 50%;
}
.widget-profile .profile-info-widget .booking-doc-img img {
    border-radius: 50%;
    height: 120px;
    width: 120px;
}
.appointment-list .profile-info-widget {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    margin-right: auto;
    text-align: left;
}
.appointment-list .profile-info-widget .booking-doc-img img {
    border-radius: 4px;
    height: 120px;
    object-fit: cover;
    width: 120px;
}
.appointments .appointment-list {
	background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
	margin-bottom: 20px;
    padding: 20px;
}
.appointments .appointment-list:last-child {
	margin-bottom: 30px;
}
.appointments .appointment-action {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.appointment-action a + a {
	margin-left: 5px;
}
.appointment-action a {
    font-size: 13px;
}
.info-details {
    list-style: none;
    margin: 0;
    padding: 0;
}
.info-details li .title {
    color: #272b41;
    font-weight: 500;
}
.info-details li .text {
    color: #757575;
    display: block;
    font-size: 16px;
    overflow: hidden;
}
.info-details li {
	margin-bottom: 10px;
}
.info-details li:last-child {
	margin-bottom: 0;
}

/*-----------------
	32. Reviews
-----------------------*/

.doc-review.review-listing {
	margin: 0;
}
.review-listing.doc-review > ul > li {
	background-color: #fff;
	border: 1px solid #f0f0f0;
	padding: 20px;
}

/*-----------------
	33. Voice call
-----------------------*/

.modal-open .main-wrapper {
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
}
.call-main-row {
	bottom: 0;
	left: 0;
	overflow: auto;
	padding-bottom: inherit;
	padding-top: inherit;
	position: absolute;
	right: 0;
	top: 0;
}
.call-main-wrapper {
	display: table;
	height: 100%;
	table-layout: fixed;
	width: 100%;
}
.call-view {
	display: table-cell;
	height: 100%;
	float: none;
	padding: 0;
	position: static;
	vertical-align: top;
	width: 75%;
}
.call-window {
	display: table;
	height: 100%;
	table-layout: fixed;
	width: 100%;
	background-color: #fff;
	border: 1px solid #f0f0f0;
}
.fixed-header {
	background-color: #fff;
	border-bottom: 1px solid #f0f0f0;
	padding: 10px 15px;
}
.fixed-header .navbar {
	border: 0 none;
	margin: 0;
	min-height: auto;
	padding: 0;
}
.fixed-header .user-info a {
    color: #272b41;
    font-weight: 500;
}
.typing-text {
	color: #20c0f3;
	font-size: 12px;
	text-transform: lowercase;
}
.last-seen {
	color: #888;
	display: block;
	font-size: 12px;
}
.custom-menu {
	margin-top: 6px;
}
.fixed-header .custom-menu {
	margin: 0 0 1px;
}
.custom-menu.nav > li > a {
    color: #bbb;
    font-size: 26px;
    line-height: 32px;
    margin-left: 15px;
    padding: 0;
}
.custom-menu.navbar-nav > li > a:hover,
.custom-menu.navbar-nav > li > a:focus {
	background-color: transparent;
}
.custom-menu .dropdown-menu {
	left: auto;
	right: 0;
}
.call-contents {
	display: table-row;
	height: 100%;
}
.call-content-wrap {
	height: 100%;
	position: relative;
	width: 100%;
}
.voice-call-avatar {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	-ms-flex: 2;
	flex: 2;
}
.voice-call-avatar .call-avatar {
	margin: 15px;
	width: 150px;
	height: 150px;
	border-radius: 100%;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 3px;
	background-color: #fff;
}
.call-duration {
	display: inline-block;
	font-size: 30px;
	margin-top: 4px;
	position: absolute;
	left: 0;
}
.voice-call-avatar .call-timing-count {
	padding: 5px;
}
.voice-call-avatar .username {
	font-size: 20px;
	font-weight: 500;
}
.call-footer {
	background-color: #fff;
	border-top: 1px solid #f0f0f0;
	padding: 15px;
}
.call-icons {
	text-align: center;
	position: relative;
}
.call-icons .call-items {
	border-radius: 5px;
	padding: 0;
	margin: 0;
	list-style: none;
	display: inline-block;
}
.call-icons .call-items .call-item,
.call-icons .call-items li.end-call-new {
	display: inline-block;
	text-align: center;
	margin-right: 5px;
}
.call-icons .call-items .call-item:last-child {
	margin-right: 0;
}
.call-icons .call-items .call-item a {
	color: #777;
	border: 1px solid #ddd;
	width: 50px;
	height: 50px;
	line-height: 50px;
	border-radius: 50px;
	display: inline-block;
	font-size: 20px;
}
.call-icons .call-items .call-item a i {
	width: 18px;
	height: 18px;
}
.user-video {
	bottom: 0;
	left: 0;
	overflow: auto;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 10;
}
.user-video img {
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 100%;
	display: block;
	margin: 0 auto;
}
.user-video video {
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 100%;
	display: block;
	margin: 0 auto;
}
.my-video {
	position: absolute;
	z-index: 99;
	bottom: 20px;
	left: 20px;
}
.my-video ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.my-video ul li {
	float: left;
	width: 120px;
	margin-right: 10px;
}
.my-video ul li img {
	border: 3px solid #fff;
	border-radius: 6px;
}
.end-call {
	position: absolute;
	top: 7px;
	right: 0;
}
.end-call a,
.call-icons .call-items .end-call-new a {
    background-color: #f06060;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    line-height: 10px;
    padding: 8px 25px;
    text-transform: uppercase;
}
.call-users {
	position: absolute;
	z-index: 99;
	bottom: 20px;
	right: 20px;
}
.call-users ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.call-users ul li {
	float: left;
	width: 80px;
	margin-left: 10px;
}
.call-users ul li img {
	border-radius: 6px;
	padding: 2px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.1);
}
.call-mute {
	width: 80px;
	height: 80px;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	text-align: center;
	line-height: 80px;
	border-radius: 6px;
	font-size: 30px;
	color: #fff;
	display: none;
	top: 0;
	border: 3px solid transparent;
}
.call-users ul li a:hover .call-mute {
	display: block;
}
.call-details {
	margin: 10px 0 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.call-info {
	margin-left: 10px;
	width: 100%;
}
.call-user-details,
.call-timing {
	display: block;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.call-description {
	white-space: nowrap;
	vertical-align: bottom;
}
.call-timing {
	color: #727272;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 14px;
	margin-top: 1px;
	overflow: hidden;
	white-space: pre;
}

/*-----------------
	34. Video Call
-----------------------*/

.content-full {
	height: 100%;
	position: relative;
	width: 100%;
}
.video-window .fixed-header {
	padding: 0;
	border: 0;
}
.video-window .fixed-header .nav > li > a {
	padding: 18px 15px;
}

/*-----------------
	35. Outgoing Call
-----------------------*/

.call-box .call-wrapper {
	height: auto;
	text-align: center;
}
.call-box .call-wrapper .call-avatar {
	margin-bottom: 30px;
	cursor: pointer;
	animation: ripple 2s infinite;
}
.call-box .call-wrapper .call-user {
	margin-bottom: 30px;
}
.call-box .call-wrapper .call-user span {
	display: block;
	font-weight: 500;
	text-align: center;
}
.call-box .call-wrapper .call-items {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.call-box .call-wrapper .call-items .call-item {
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid transparent;
    border-radius: 100%;
    color: #fff;
    line-height: 0;
    margin: 0 5px;
    padding: 15px;
}
.call-box .call-wrapper .call-items .call-item:hover {
	opacity: 0.9;
}
.call-box .call-wrapper .call-items .call-item:first-child {
	margin-top: -30px;
}
.call-box .call-wrapper .call-items .call-item:last-child {
	margin-top: -30px;
}
.call-box .call-wrapper .call-items .call-item.call-end {
	padding: 20px;
	margin: 30px 20px 0;
	background: #f06060;
	border: 1px solid #f06060;
	color: #fff;
	line-height: 0;
	border-radius: 100%;
}
.call-box .call-wrapper .call-items .call-item.call-start {
	padding: 20px;
	margin: 30px 20px 0;
	background: #55ce63;
	border: 1px solid #55ce63;
	color: #fff;
	line-height: 0;
	border-radius: 100%;
}
.call-box.incoming-box .call-wrapper .call-items .call-item.call-start {
	margin: 0 10px;
}
.call-box.incoming-box .call-wrapper .call-items .call-item.call-end {
	margin: 0 10px;
}
.call-box .call-avatar {
    border-radius: 100%;
    height: 140px;
    max-width: 140px;
    min-width: 140px;
    position: relative;
    width: 100%;
    border: 10px solid #fafafa;
}
.call-box .btn {
    background: rgba(0, 0, 0, 0);
    transition: all 0.3s ease 0s;
}
@-webkit-keyframes ripple {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
	}
	100% {
		-webkit-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
	}
}
@keyframes ripple {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
	}
	100% {
		-moz-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
		box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
	}
}

/*-----------------
	36. Incoming Call
-----------------------*/

.incoming-btns {
	margin-top: 20px;
}
.call-wrapper {
    position: relative;
    height: calc(100vh - 145px);
}
.call-page .footer {
	display: none;
}
.dropdown-action .dropdown-toggle::after {
	display: none;
}
.call-modal .modal-body {
	padding: 40px;
}
.call-modal .modal-content {
    border: 0;
    border-radius: 10px;
}
.call-box .call-wrapper .call-user h4 {
	font-size: 24px;
}

/*-----------------
	37. Terms and Conditions
-----------------------*/

.terms-text {
    margin-bottom: 20px;
}
.terms-text h4 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}
.terms-text p {
    color: #666;
    display: inline-block;
    font-size: 16px;
}

/*-----------------
	38. Blog
-----------------------*/

.blog {
	border: 1px solid #f0f0f0;
	background-color: #fff;
	border-radius: 4px;
	margin-bottom: 30px;
	padding: 1.5rem;
	position: relative;
}
.blog-image {
	overflow: hidden;
}
.blog-image,
.blog-image > a,
.blog-image img {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
}
.blog-image img {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.blog-image a:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}

.blog-image span {
	margin:0;
}

.blog-image span:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.blog-image {
	margin-bottom: 30px;
}
.blog-content {
	position: relative;
}
.blog-title {
	font-size: 22px;
	margin: 0 0 10px;
}
.blog-title a {
	color: #272b41;
}
.blog-title a:hover {
	color: #e97a24;
}
.blog-content p {
	color: #666;
	font-size: 14px;
	margin: 0 0 30px;
}
.read-more {
    display: inline-block;
    font-size: 16px;
    padding: 0;
    color: #e97a24;
    text-decoration: underline;
    font-weight: bold;
}
.blog-info {
    color: #757575;
    font-size: 14px;
    margin-bottom: 15px;
}
.blog-info a {
	color: #757575;
}
.blog-info a:hover {
	color: #757575;
}
.blog-info i {
	color: #e97a24;
	font-size: 14px;
	margin-right: 5px;
}
.entry-meta {
	color: #757575;
	font-size: 14px;
	list-style: none;
	margin-bottom: 15px;
	padding: 0;
}
.entry-meta li {
    display: inline-block;
    margin-right: 15px;
}
.entry-meta li:last-child {
	margin-right: 0;
}
.blog-pagination .pagination {
	margin-bottom: 30px;
}
.blog-pagination .pagination li a {
    padding: 12px 20px;
    font-weight: 600;
    color: #2E3842;
    margin-right: 10px;
    border-radius: inherit !important;
	border-color: #f0f0f0;
}
.blog-pagination .pagination li.disabled a {
	border-color: #f0f0f0;
}
.blog-pagination .pagination li.active a {
	background: #3065b5;
	border-color: transparent;
	color: #ffffff;
}
.post-left ul {
	margin: 0;
	padding-left: 0;
	list-style: none;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.post-left ul li {
	margin-bottom: 5px;
	margin-right: 20px;
}
.post-left ul li:last-child {
	margin-right: 0;
}
.post-author a {
	display: flex;
	align-items: center;
}
.video {
	position: relative;
	padding-bottom: 56%;
	height: 0;
	overflow: hidden;
}
.video iframe,
.video object,
.video embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
}
.latest-posts {
	margin: 0;
	padding: 0;
}
.latest-posts li {
	display: table;
	width: 100%;
	margin-bottom: 20px;
}
.latest-posts li:last-child {
	padding-bottom: 0px;
	margin-bottom: 0px;
	border-bottom: none;
}
.post-thumb {
	border-radius: 4px;
	width: 80px;
	float: left;
	overflow: hidden;
}
.post-thumb a img {
	border-radius: 4px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.post-thumb a:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.post-info {
	margin-left: 95px;
}
.post-info h4 {
	font-size: 15px;
	margin: 0 0 5px;
}
.post-info h4 a {
	color: #272b41;
}
.post-info h4 a:hover {
	color: #e97a24;
}
.post-info p {
	color: #757575;
	font-size: 12px;
	margin: 0;
}
.post-info p i {
	color: #20c0f3;
	font-size: 14px;
	margin-right: 4px;
}
.category-widget .categories {
	list-style: none;
	margin: 0;
	padding: 0;
}
.category-widget .categories li {
    margin-bottom: 20px;
}
.category-widget .categories li:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.category-widget .categories li a {
    color: #2E3842;
    font-size: 15px;
    font-weight: 500;
}
.category-widget .categories li a span {
    float: right;
    color: #757575;
}
.category-widget .categories li a:hover {
	color: #e97a24;
}
.category-widget .categories > li > a > i {
	color: #20c0f3;
	font-size: 18px;
	margin-right: 10px;
}
.tags-widget .card-body {
	padding-bottom: 1rem;
}
.tags {
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
}
.tags li {
	float: left;
}
.tag {
	background-color: #fff;
	border-radius: 4px;
	color: #272b41;
	padding: 5px 10px;
	position: relative;
	margin: 0 .5rem .5rem 0;
	font-size: 14px;
	border: 1px solid #ccc;
	display: inline-block;
}
.tag:hover {
	background-color: #3065b5;
	border-color: #3065b5;
	color: #fff;
}
.comment-by {
	display: block;
	font-size: 14px;
	line-height: 21px;
	margin: 0 0 10px;
}
.comments-list .comment-block p {
    font-size: 14px;
    margin-bottom: 5px;
}

/*-----------------
	39. Blog Details
-----------------------*/

.blog-view .blog-title {
	font-size: 24px;
}
.blog-content p:last-child {
	margin-bottom: 0;
}
.blog-view .blog-info {
	border: 0;
	margin-bottom: 20px;
	padding: 0;
}
.social-share {
	float: left;
	list-style: none;
	margin: 0;
	padding: 0;
}
.social-share > li {
	display: inline-block;
	float: left;
	margin-left: 10px;
	text-align: center;
}
.social-share > li:first-child {
	margin-left: 0;
}
.social-share > li > a {
	border: 1px solid #dfdfdf;
	color: #666;
	display: inline-block;
	font-size: 22px;
	height: 40px;
	line-height: 40px;
	width: 40px;
}
.social-share > li > a:hover {
	background-color: #3065b5;
	color: #fff;
	border-color: #3065b5;
}
.blog-reply > a {
	color: #999;
	font-size: 12px;
	font-weight: 500;
}
.blog-date {
	color: #999;
	font-size: 12px;
}
.blog-comments .comments-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.blog-comments .comments-list li {
	clear: both;
	padding-left: 80px;
}
.blog-comments .comments-list li .comment {
	margin-bottom: 20px;
}
.blog-comments .comments-list li .comment-author {
	left: 0;
	position: absolute;
}
.blog-comments .comments-list li img.avatar {
	height: 58px;
	width: 58px;
	border-radius: 58px;
}
.blog-comments .comment-btn {
    color: #e97a24;
    display: inline-block;
    font-weight: 500;
    font-size: 15px;
}
.blog-author-name {
    color: #272b41;
    font-size: 16px;
    font-weight: 600;
}
.new-comment label {
	font-weight: 500;
}
.comment-submit .btn {
	background-color: #20c0f3;
	border-color: #20c0f3;
	border-radius: 0;
	font-size: 18px;
	padding: 8px 26px;
	color: #fff;
}
.about-author-img {
	background-color: #fff;
	height: 100px;
	overflow: hidden;
	position: absolute;
	width: 100px;
}
.author-details {
	margin-left: 120px;
}
.about-author {
	min-height: 100px;
}
.author-details .blog-author-name {
	display: inline-block;
	margin-bottom: 10px;
}
.post-author img {
    border-radius: 100%;
    width: 28px;
    margin-right: 5px;
}

/*-----------------
	40. Blog Grid
-----------------------*/

.grid-blog .blog-title {
    font-size: 18px;
    margin: 0 0 5px;
    min-height: 43px;
}
.grid-blog .blog-content p {
	margin: 0 0 15px;
}
.grid-blog .blog-info {
	margin-bottom: 15px;
}
.grid-blog .blog-image {
	margin-bottom: 20px;
}
.blog.grid-blog {
	padding: 20px;
}
.grid-blog .entry-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 0;
}
.grid-blog .entry-meta li {
	margin-bottom: 10px;
}
.grid-blog .entry-meta li:last-child {
	flex: 0 0 100px;
	max-width: 100px;
}
.grid-blog .post-author {
    width: 189px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.grid-blog .post-author a:hover {
	color: #20c0f3
}

/*-----------------
	41. Map List & Grid
-----------------------*/

.map-page .footer {
	display: none;
}
.map-page .header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1030;
}
.map-page .content {
	padding-top: 150px;
}
.map-right {
	bottom: 0;
    height: 100%;
    min-height: 100%;
	padding-right: 30px;
    position: fixed;
    right: 0;
    top: 150px;
    z-index: 1029;
}
.map-listing {
    height: calc(100vh - 145px);
    width: 100%;
}
.map-listing .profile-widget {
    width: 200px !important;
    padding: 0;
    border: 0;
	margin-bottom: 0;
}
.map-right .available-info {
	margin-bottom: 0;
}
.view-icons {
    display: inline-flex;
    float: right;
}
.view-icons a {
	align-items: center;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	color: #212529;
	display: flex;
	font-size: 20px;
	justify-content: center;
	padding: 4px 10px;
	text-align: center;
	margin-left: 10px;
	width: 44px;
	height: 44px;
}
.view-icons a:hover {
	border-color: #3065b5;
	color: #3065b5;
}
.view-icons a.active {
    background-color: #3065b5;
	border-color: #3065b5;
	color: #fff;
}


/*-----------------
	42. Loader
-----------------------*/

#loader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	z-index: 999999;
}
.loader {
	display: inline-block;
	height: 50px;
	left: 50%;
	margin-left: -25px;
	margin-top: -25px;
	width: 50px;
	position: absolute;
	top: 50%;
}
.loader span {
	-webkit-animation: loader 1.3s linear infinite;
	animation: loader 1.3s linear infinite;
	background-color: #20c0f3;
	border-radius: 100%;
	display: inline-block;
	height: 50px;
	position: absolute;
	width: 50px;
}
.loader span:last-child {
	animation-delay: -0.8s;
	-webkit-animation-delay: -0.8s;
}
@keyframes loader {
	0% {
		transform: scale(0, 0);
		opacity: 0.5;
	}
	100% {
		transform: scale(1, 1);
		opacity: 0;
	}
}
@-webkit-keyframes loader {
	0% {
		-webkit-transform: scale(0, 0);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: scale(1, 1);
		opacity: 0;
	}
}

/*-----------------
	43. Responsive
-----------------------*/

@media only screen and (min-width:768px) {
	.avatar-xxl {
		width: 8rem;
		height: 8rem;
	}
	.avatar-xxl .border {
		border-width: 4px !important;
	}
	.avatar-xxl .rounded {
		border-radius: 12px !important;
	}
	.avatar-xxl .avatar-title {
		font-size: 42px;
	}
	.avatar-xxl.avatar-away:before,
	.avatar-xxl.avatar-offline:before,
	.avatar-xxl.avatar-online:before {
		border-width: 4px;
	}
}
@media (min-width: 992px) {
	.main-nav > li {
		margin-right: 12px;
	}
	.main-nav > li:last-child {
		margin-right: 0;
	}
	.main-nav li {
		display: block;
		position: relative;
	}
	.main-nav li:last-child {
		margin-right: 0;
	}
	.main-nav > li > a {
		line-height: 85px;
		padding: 0 !important;
	}
	.main-nav > li > a > i {
		font-size: 12px;
		margin-left: 3px;
	}
	.main-nav li > ul {
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0 0 3px  rgba(0, 0, 0, 0.1);
		display: block;
		font-size: 14px;
		left: 0;
		margin: 0;
		min-width: 200px;
		opacity: 0;
		padding: 0;
		position: absolute;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		-webkit-transform: translateY(20px);
		-ms-transform: translateY(20px);
		transform: translateY(20px);
		top: 100%;
		visibility: hidden;
		z-index: 1000;
	}
	.main-nav li .submenu::before {
		/* border: 7px solid #fff;
		border-color: transparent transparent #fff #fff;
		box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
		content: "";
		left: 45px;
		position: absolute;
		top: 2px;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		-webkit-transform: rotate(135deg);
		transform: rotate(135deg); */
	}
	.main-nav li.has-submenu:hover > .submenu {
		visibility: visible;
		opacity: 1;
		margin-top: 0;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
	.main-nav .has-submenu.active > a {
		color: #e97a24;
	}
	.main-nav .has-submenu.active .submenu li.active > a {
		color: #e97a24;
	}
	.main-nav > li .submenu li:first-child a {
		border-top: 0;
	}
	.main-nav > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
		visibility: visible;
		opacity: 1;
		margin-top: -1px;
		margin-right: 0;
	}
	.main-nav > li .submenu > li .submenu {
		left: 100%;
		top: 0;
		margin-top: 10px;
	}
	.main-nav li .submenu a:hover {
		color: #e97a24;
		letter-spacing: 0.5px;
		padding-left: 20px;
	}
	.main-nav > .has-submenu > .submenu > .has-submenu > .submenu::before {
		top: 20px;
		margin-left: -35px;
		box-shadow: 1px 1px 0 0 rgba(0,0,0,.15);
		border-color: transparent #fff #fff transparent;
	}
	.header-navbar-rht li.show > .dropdown-menu {
		visibility: visible;
		opacity: 1;
		margin-top: 0;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
	.header-navbar-rht li .dropdown-menu {
		border-radius: 5px;
		padding: 0;
		margin: 0;
		min-width: 200px;
		visibility: hidden;
		opacity: 0;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		display: block;
		-webkit-transform: translateY(20px);
		-ms-transform: translateY(20px);
		transform: translateY(20px);
	}
}

@media (min-width: 1400px) {
	.container {
		max-width: 1430px;
		padding: 0 30px;
	}
} 

@media only screen and (max-width: 1399px) {
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 180px;
	}
	.row.row-grid {
		margin-left: -8px;
		margin-right: -8px;
	}
	.row.row-grid > div {
		padding-right: 8px;
		padding-left: 8px;
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
}
@media only screen and (max-width: 1199px) {
	.header-nav {
		padding-left: 20px;
		padding-right: 20px;
	}

	.container-fluid, .container {
		padding-left: 20px;
		padding-right: 20px;
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 150px;
	}
	.chat-cont-left {
		-ms-flex: 0 0 40%;
		flex: 0 0 40%;
		max-width: 40%;
	}
	.chat-cont-right {
		-ms-flex: 0 0 60%;
		flex: 0 0 60%;
		max-width: 60%;
	}
	.row.row-grid > div {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.dash-widget {
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}
	.circle-bar {
		margin: 0 0 15px;
	}
	.header-navbar-rht li.contact-item {
		display: none;
	}
	.map-right {
		height: 450px;
		position: static;
		min-height: inherit;
		margin-bottom: 30px;
		padding-right: 15px;
	}
	.map-listing {
		height: 450px;
	}
	.map-page .content {
		padding-top: 100px;
	}
}
@media only screen and (max-width: 991.98px) {
	
	.main-nav {
		padding: 0;
		-ms-flex-direction: column;
		flex-direction: column;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
	}
	.main-nav ul {
		background-color: #042f65;
		display: none;
		list-style: none;
		margin: 0;
		padding-left: 0;
	}
	.main-nav > li {
		border-bottom: 1px solid #193c6b;
		margin-left: 0;
	}
	.main-nav li + li {
		margin-left: 0;
	}
	.main-nav > li > a {
		line-height: 1.5;
		padding: 15px 20px !important;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
	}
	.main-nav > li > a > i {
		float: right;
		margin-top: 5px;
	}
	.main-nav > li .submenu li a {
		border-top: 0;
		color: #fff;
		padding: 10px 15px 10px 35px;
	}
	.main-nav > li .submenu ul li a {
		padding: 10px 15px 10px 45px;
	}
	.main-nav > li .submenu > li.has-submenu > a::after {
		content: "\f078";
	}
	.main-nav .has-submenu.active > a {
		color: #e97a24;
	}
	.main-nav .has-submenu.active .submenu li.active > a {
		color: #e97a24;
	}
	.login-left {
		display: none;
	}
	.main-menu-wrapper {
		order: 3;
		width: 260px;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		overflow-x: hidden;
		overflow-y: auto;
		z-index: 1060;
		transform: translateX(-260px);
		transition: all 0.4s;
		background-color: #3065b5;
	}
	.menu-header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.navbar-header {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
	}
	#mobile_btn {
		display: inline-block;
	}
	.section-search {
		min-height: 330px;
		padding: 60px 0 100px;
	}
	.top-service-col {
    	padding: 40px 0;
    	margin-top: -130px;
	}
	.feature-section {
    	padding: 40px 0 120px;
	}
	.aboutus-col {
    	padding: 40px 0;
	}
	.testimonial {
    	padding: 40px 0 80px;
	}
	.count-section {
    	padding: 40px 0;
	}
	.doctors-col {
    	padding: 40px 0;
	}
	.doctor-slider .slick-slide {
    	width: 340px;
	}
	.doctors-col .slick-slider {
    	padding-bottom: 30px;
    	margin-top: 0;
	}
	.doctors-col .doctors-title h2, .section-header h2, .aboutus-col .about-right h2, .testimonial .testimonial-item h2 {
		padding-bottom: 0;
	}
	.appoinment-section {
		padding: 40px 0;
	}
	.appmt-form .card {
		padding-bottom: 10px;
	}
	.blog-section {
    	padding: 40px 0;
	}
	.footer .footer-top {
    	padding: 40px 0 20px;
	}
	.footer .footer-bottom .copyright {
    	padding: 20px 0;
    }
    .feature-column {
    	padding: 20px 5px 0;
	}
	.footer-widget {
		margin-bottom: 30px;
	}
	.about-right {
		padding-top: 30px;
	}
	.about-left img {
		width: 100%;
	}
	.chat-cont-left, .chat-cont-right {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
		width: 100%;
	}
	.chat-cont-left {
		border-right: 0;
	}
	.chat-cont-right {
		position: absolute;
		right: -100%;
		top: 0;
		opacity: 0;
		visibility: hidden;
	}
	.chat-cont-right .chat-header {
		justify-content: start;
		-webkit-justify-content: start;
		-ms-flex-pack: start;
	}
	.chat-cont-right .chat-header .back-user-list {
		display: block;
	}
	.chat-cont-right .chat-header .chat-options {
		margin-left: auto;
	}
	.chat-window.chat-slide .chat-cont-left {
		left: -100%;
	}
	.chat-window.chat-slide .chat-cont-right {
		right: 0;
		opacity: 1;
		visibility: visible;
	}
	.day-slot li.left-arrow {
		left: -10px;
	}
	.container {
		max-width: 100%;
	}
	.appointments .appointment-action {
		margin-top: 10px;
	}
	.appointments .appointment-list {
		display: block;
	}
	.banner-wrapper {
		max-width: 720px;
	}
	.search-box .search-info {
		-ms-flex: 0 0 410px;
		flex: 0 0 410px;
		width: 410px;
	}
	.banner-wrapper .banner-header h1 {
		font-size: 2.25rem;
		max-width: 100%;
	}
	.feature-section .left h2, .aboutus-col .about-right h2, .testimonial .testimonial-item h2,.doctors-col .doctors-title h2, .section-header h2 {
    	font-size: 2rem;
	}
	.blog-section .col-12.col-md-6 {
    	margin-bottom: 50px;
	}
	.blog-bottom {
		padding-top: 5px !important;
	}
	.banner-wrapper .banner-header p {
		line-height: 1.5;
	}
	.service-box p {
		line-height: 1.5;
	}
	.feature-box p {
		max-width: 300px;
	}
	.service-box .card {
		margin-bottom: 60px;
	}
	.top-service-col .col-12.col-md-6.col-lg-4:last-child .card {
		margin-bottom: 1.5rem;
	}
	.dct-border-rht {
		border-bottom: 1px solid #f0f0f0;
		border-right: 0;
		margin-bottom: 20px;
		padding-bottom: 15px;
	}
	.card-label > label {
		font-size: 12px;
	}
	.time-slot li .timing.selected::before {
		 display: none; 
		width: 15%;
	}
	.review-listing .recommend-btn {
		float: none;
	}
	.dash-widget {
		flex-direction: unset;
		text-align: left;
	}
	.circle-bar {
		margin: 0 15px 0 0;
	}
	.call-wrapper {
		height: calc(100vh - 140px);
	}
	.sidebar-overlay.opened {
		display: block;
	}
	.about-content {
		margin-bottom: 30px;
	}
	.slick-prev,
	.slick-next {
    	margin: 0 -40px;
	}
	.feature-box {
    	width: 100%;
	}
	.top-header {
		display: none;
	}
	.search-holder input {
    	min-width: 300px;
	}	
	.location-col .col-12.col-md-6 {
		margin-bottom: 40px;
	}
}
@media only screen and (max-width: 849.98px) {
	.row.row-grid > div {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}
@media only screen and (max-width: 767.98px) {
	body {
		font-size: 0.875rem;
	}
	h1, .h1 {
		font-size: 1.75rem !important;
	}
	h2, .h2 {
		font-size: 1.5rem !important;
	}
	h3, .h3 {
		font-size: 1.25rem !important;
	}
	h4, .h4 {
		font-size: 1rem !important;
	}
	h5, .h5 {
		font-size: 0.875rem !important;
	}
	h6, .h6 {
		font-size: 0.75rem !important;
	}
	.content {
		padding: 15px 0 0;
	}
	.account-page .content {
		padding: 15px 0;
	}
	.container-fluid, .container {
		padding-left: 15px;
		padding-right: 15px;
	}	
	.card {
		margin-bottom: 20px;
	}
	.profile-sidebar {
		margin-bottom: 20px;
	}
	.appointment-tab {
		margin-bottom: 20px;
	}
	.features-slider .slick-dots, .features-img img {
		text-align: center;
		margin: 0 auto;
	}
	.doctor-slider {
		margin-top: 25px;
	}
	.breadcrumb-bar {
		height: auto;
	}
	.login-right {
		margin: 0 15px;
		padding: 15px;
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 250px;
	}
	.chat-window .chat-cont-right .chat-header .media .media-body {
		display: none;
	}
	.banner-wrapper .banner-header h1 {
		font-size: 2rem;
	}
	.banner-wrapper .banner-header p {
		font-size: 1rem;
	}
	.section-header h2 {
		font-size: 1.875rem;
	}
	.section-header .sub-title {
		font-size: 0.875rem;
	}
	.speicality-item p {
		font-size: 0.875rem;
	}
	.section-header p {
		font-size: 0.9375rem;
	}
	.footer-title {
		font-size: 1.125rem;
	}
	.search-box {
		max-width: 535px;
		margin: 0 auto;
	}
	.search-box form {
		-ms-flex-direction: column;
		flex-direction: column;
		word-wrap: break-word;
		background-clip: border-box;
	}
	.search-box .search-location {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		width: 100%;
	}
	.search-box .search-info {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		width: 100%;
	}
	.search-box .search-btn {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		min-height: 46px;
		width: 100%;
	}
	.search-box .search-btn span {
		display: inline-block;
		margin-left: 5px;
		text-transform: uppercase;
	}
	.section-search {
		background: #f9f9f9;
	}
	.day-slot li span {
		font-size: 16px;
		text-transform: unset;
	}
	.time-slot li .timing span {
		display: block;
	}
	.submit-section.proceed-btn {
		margin-bottom: 20px;
	}
	.day-slot li small.slot-year {
		display: none;
	}
	.success-cont h3 {
		font-size: 22px;
	}
	.view-inv-btn {
		font-size: 14px;
		padding: 10px 30px;
	}
	.invoice-info.invoice-info2 {
		text-align: left;
	}
	.invoice-item .invoice-details {
		text-align: left;
	}
	.section-search, .section-doctor, .section-features {
		padding: 50px 0;
	}
	.specialities-slider .slick-slide {
		margin-right: 15px;
	}
	.about-content a {
		padding: 12px 20px;
	}
	.submit-section .submit-btn {
		padding: 10px 20px;
		font-size: 15px;
		min-width: 105px;
	}
	.policy-menu {
		margin-top: 10px;
		text-align: left;
	}
	.booking-doc-info .booking-doc-img {
		width: 75px;
	}
	.booking-doc-info .booking-doc-img img {
		height: 75px;
		width: 75px;
	}
	.btn.btn-danger.trash {
		margin-bottom: 20px;
	}
	.nav-tabs.nav-tabs-bottom > li > a.active, .nav-tabs.nav-tabs-bottom > li > a.active:hover, .nav-tabs.nav-tabs-bottom > li > a.active:focus {
		background-color: #f5f5f5;
	}	
	.nav-tabs.nav-justified {
		border-bottom: 1px solid #ddd;
	}
	.nav-tabs.nav-justified > li > a.active,
	.nav-tabs.nav-justified > li > a.active:hover,
	.nav-tabs.nav-justified > li > a.active:focus {
		border-color: transparent transparent transparent #20c0f3;
		border-left-width: 2px;
	}
	.nav-tabs {
		border-bottom: 0;
		position: relative;
		background-color: #fff;
		padding: 5px 0;
		border: 1px solid #ddd;
		border-radius: 3px;
	}
	.nav-tabs .nav-item {
		margin-bottom: 0;
	}
	.nav-tabs > li > a {
		border-width: 2px;
		border-left-color: transparent;
	}
	.nav-tabs .nav-link {
		border-width: 2px;
	}
	.nav-tabs > li > a:hover,
	.nav-tabs > li > a:focus {
		background-color: #fafafa;
	}
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active,
	.nav-tabs > li > a.active,
	.nav-tabs > li > a.active:hover,
	.nav-tabs > li > a.active:focus {
		border-color: transparent transparent transparent #20c0f3 !important;
		border-left-width: 2px;
	}
	.nav-tabs > li.open:not(.active) > a,
	.nav-tabs > li.open:not(.active) > a:hover,
	.nav-tabs > li.open:not(.active) > a:focus {
		background-color: #fafafa;
	}
	.nav-tabs.nav-tabs-solid {
		padding: 5px;
	}
	.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
		border-radius: 5px;
	}
	.nav-tabs.nav-tabs-solid > li > a {
		border-left-width: 0!important;
	}
	.nav-tabs-justified {
		border-bottom: 1px solid #ddd;
	}
	.nav-tabs-justified > li > a.active,
	.nav-tabs-justified > li > a.active:hover,
	.nav-tabs-justified > li > a.active:focus {
		border-width: 0 0 0 2px;
		border-left-color: #20c0f3;
	}
	.review-listing > ul li .comment .comment-body .meta-data span.comment-date {
		margin-bottom: 5px;
	}
	.review-listing > ul li .comment .comment-body .meta-data .review-count {
		position: unset;
	}
	.my-video ul li {
		width: 50px;
	}
	.call-users ul li {
		width: 50px;
	}
	.call-mute {
		font-size: 20px;
		height: 50px;
		line-height: 50px;
		width: 50px;
	}
	.call-duration {
		font-size: 24px;
	}
	.voice-call-avatar .call-avatar {
		height: 100px;
		width: 100px;
	}
	.user-tabs {
		margin-top: 1.5rem;
	}
	.user-tabs .nav-tabs > li > a {
		border-left: 2px solid transparent;
		border-bottom: 0;
		padding: .5rem 1rem;
	}
	.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active, 
	.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:hover, 
	.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:focus {
		border-left-width: 2px;
		color: #20c0f3;
	}
	.doctor-widget {
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}
	.doc-info-right {
		margin-left: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.doc-info-left {
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.clinic-services {
		display: none;
	}
	.doctor-img {
		margin: 0 auto 20px;
	}
	.doctor-action {
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
	.row.row-grid > div {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.breadcrumb-bar .breadcrumb-title {
		font-size: 18px;
	}
	.dash-widget h6 {
		font-size: 15px;
	}
	.dash-widget h3 {
		font-size: 20px;
	}
	.dash-widget p {
		font-size: 13px;
	}
	.doctor-widget .doc-name {
		font-size: 18px;
	}
	.exp-title, .booking-total ul li span, .booking-total ul li .total-cost {
		font-size: 14px;
	}
	.invoice-item .customer-text {
		font-size: 16px;
	}
	.call-wrapper {
		height: calc(100vh - 115px);
	}
	.appointment-tab .nav-tabs {
		padding: 1.5rem;
	}
	.submit-btn-bottom {
		margin-bottom: 20px;
	}
	.service-list ul li {
		width: 50%;
	}
	.grid-blog .blog-title {
		min-height: inherit;
	}
	.blog-title {
		font-size: 20px;
	}
	.blog-view .blog-title {
		font-size: 22px;
	}
	.top-service-col {
    	margin-top: -90px;
	}
	.service-box {
		margin-bottom: 25px;
	}
	.aboutus-col {
    	padding: 40px 0;
	}
	.count-section .col-12.col-md-4.col-lg-3 {
		margin-bottom: 20px;
	}
	.count-section .col-12.col-md-3:last-child {
		margin-bottom: 0;
	}
	.policy-menu li {
    	margin-bottom: 8px;
	}	
	.blog-section .col-12.col-md-3 {
		margin-bottom: 40px;
	}
	.count-section .col-12.col-md-3 {
		margin-bottom: 20px;
	}
	.count-section .col-12.col-md-3:last-child {
		margin-bottom: 0;
	}
	.appoinment-section .section-header h2{
		margin-bottom: .5rem;
	}
	.btn-yellow {
    	font-size: 16px;
	}
	.view-all {
    	font-size: 16px;
	}
}

@media only screen and (max-width: 575.98px) {
	body {
		font-size: 0.8125rem;
	}
	h1, .h1 {
		font-size: 1.5rem;
	}
	h2, .h2 {
		font-size: 1.25rem;
	}
	h3, .h3 {
		font-size: 1rem;
	}
	h4, .h4 {
		font-size: 0.875rem;
	}
	h5, .h5 {
		font-size: 0.75rem;
	}
	h6, .h6 {
		font-size: 0.65rem;
	}
	.card {
		margin-bottom: 0.9375rem;
	}
	.card-body {
		padding: 1.25rem;
	}
	.card-header {
		padding: .75rem 1.25rem;
	}
	.card-footer {
		padding: .75rem 1.25rem;
	}
	.header-nav {
		padding-left: 15px;
		padding-right: 15px;
	}
	.header-navbar-rht {
		display: none;
	}
	.main-nav li.login-link {
		display: block;
	}
	.navbar-header {
		width: 100%;
	}
	#mobile_btn {
		left: 0;
		margin-right: 0;
		padding: 0 15px;
		position: absolute;
		z-index: 99;
	}
	.navbar-brand.logo {
		width: 100%;
		text-align: center;
		margin-right: 0;
		margin-left: 30px;
	}
	.menu-logo {
		font-size: 28px;
    	font-weight: 900;
	}
	.navbar-brand.logo img {
		height: 40px;
	}
	.search-box form {
		display: block;
	}
	.search-box .search-location {
		width: 100%;
		-ms-flex: none;
		flex: none;
	}
	.search-box .search-info {
		width: 100%;
		-ms-flex: none;
		flex: none;
	}
	.banner-wrapper .banner-header h1 {
		font-size: 28px;
	}
	.section-header h2 {
		font-size: 1.5rem;
	}
	.section-header .sub-title {
		font-size: 0.875rem;
	}
	.speicality-item p {
		font-size: 0.875rem;
	}
	.section-header p {
		font-size: 0.9375rem;
	}
	.footer-title {
		font-size: 1.125rem;
	}
	.booking-schedule.schedule-widget {
		overflow-x: auto;
	}
	.booking-schedule.schedule-widget > div {
		width: 730px;
	}
	.booking-schedule .day-slot ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.booking-schedule .day-slot li {
		-ms-flex: 0 0 100px;
		flex: 0 0 100px;
		width: 100px;
	}
	.booking-schedule .time-slot ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.booking-schedule .time-slot li {
		-ms-flex: 0 0 100px;
		flex: 0 0 10px;
		width: 100px;
		/* float: left;
    padding-left: 5px;
    padding-right: 5px;
    width: 14.28%; */
	}
	.booking-schedule .time-slot li .timing span {
		display: inline-block;
	}
	.booking-schedule .day-slot li.right-arrow {
		right: -20px;
	}
	.booking-doc-info .booking-doc-img {
		width: 70px;
	}
	.booking-doc-info .booking-doc-img img {
		height: 70px;
		width: 70px;
	}
	.voice-call-avatar .call-avatar {
		height: 80px;
		width: 80px;
	}
	.call-duration {
		display: block;
		margin-top: 0;
		margin-bottom: 10px;
		position: inherit;
	}
	.end-call {
		margin-top: 10px;
		position: inherit;
	}
	.user-tabs .med-records {
		min-width: 110px;
	}
	.pagination-lg .page-link {
		font-size: 1rem;
		padding: 0.5rem 0.625rem;
	}
	.row.row-grid > div {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.edit-link {
		font-size: 14px;
		margin-top: 2px;
	}
	.invoice-content {
		padding: 1.25rem;
	}
	.change-avatar .profile-img img {
		height: 80px;
		width: 80px;
	}
	.submit-btn-bottom {
		margin-bottom: 0.9375rem;
	}
	.service-list ul li {
		width: 100%;
	}
	.blog {
		padding: 1.25rem;
	}
	.blog-title {
		font-size: 18px;
	}
	.blog-view .blog-title {
		font-size: 20px;
	}
	.blog-info i {
		font-size: 14px;
	}
	.post-left ul li {
		margin-right: 10px;
	}
	.post-left ul li:last-child {
		margin-right: 10px;
	}
	.about-author-img {
		height: 60px;
		width: 60px;
	}
	.author-details {
		margin-left: 80px;
	}
	.blog-comments .comments-list li img.avatar {
		border-radius: 40px;
		height: 40px;
		width: 40px;
	}
	.blog-comments .comments-list li {
		padding: 10px 0 0 50px;
	}
	.read-more {
		font-size: 14px;
	}	
	.doctor-slider .slick-slide {
    	width: 250px;
	}
}

@media only screen and (max-width:479px) {
	.section-search {
		min-height: 400px;
		padding: 60px 0 40px;
	}
	.specialities-slider .slick-slide {
		margin-right: 10px;
	}
	.speicality-img {
		width: 120px;
		height: 120px;
	}
	.speicality-img img {
		width: 50px;
	}
	.clinic-booking a.view-pro-btn {
		width: 100%;
		margin-bottom: 15px;
	}
	.clinic-booking a.apt-btn {
		width: 100%;
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 160px;
	}
	.section-features, .section-features {
		background-color: #fff;
		padding: 30px 0;
	} 
	.login-header h3 a {
		color: #0de0fe;
		float: none;
		font-size: 15px;
		margin-top: 10px;
		text-align: center;
		display: block;
	}
	.login-header h3 {
		text-align: center;
	}
	.appointments .appointment-list {
		text-align: center;
	}
	.appointment-list .profile-info-widget {
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.appointment-list .profile-info-widget {
		text-align: center;
	}
	.appointment-list .profile-info-widget .booking-doc-img {
		margin: 0 0 15px;
	}
	.appointment-list .profile-info-widget .booking-doc-img img {
		border-radius: 50%;
		height: 100px;
		width: 100px;
	}
	.appointment-list .profile-det-info {
		margin-bottom: 15px;
	}
	.appointments .appointment-action {
		margin-top: 0;
	}
	.user-tabs .nav-tabs .nav-item {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
	}
	.review-listing .recommend-btn span {
		display: block;
		margin-bottom: 10px;
	}
	.review-listing > ul li .comments-reply {
		margin-left: 0;
	}
	.schedule-nav .nav-item{border:1px solid #dee2e6}
	.schedule-nav .tab-view a {
		border: 1px solid #dcddea;
    border-radius: 4px;
    padding: 6px 15px;
    text-transform: uppercase;
    font-size: 14px;}
	.schedule-nav .nav-tabs li {
		display: block;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		margin-right: 0;
	}
	.schedule-nav .nav-tabs a.active {
		background: #ff4877;
		border: 1px solid #ff4877 !important;
		color: #fff;
	}
	.schedule-nav .nav-item.nav-link {
		border: 1px solid #ccc;
		border-radius: 4px;
	}
	.fc-header-toolbar .fc-left {
		margin-bottom: 10px;
	}
	.fc-header-toolbar .fc-right {
		margin-bottom: 10px;
	}
	.search-holder {
		width: 100%;
	}
	.search-holder .btn {
		width: 100%;
	}
}

/* dependent styles */
.dependent .help-title {
	text-align: center;
	padding-top: 70px;
	padding-bottom: 50px;
	font-size:30px;
}
.card-body.dependent .row {
	padding-bottom: 50px;
}
.depedent-name {
	/* text-align: center; */
}
.dependent-img {
	padding: 0px;
	width: 130px;
	border-radius: 50%;
	border: 4px solid;
}
.add-dependent-link i.fa.fa-plus.add-dependent-img {
	font-size: 50px;
	border-radius: 50%;
	border: 4px solid;
	padding: 30px 35px;
	outline: unset;
}
.btn-group-toggle>.btn:first-child {
	border-bottom-left-radius: 30px;
	border-top-left-radius: 30px;
}
.btn-group-toggle>.btn:last-child {
	border-bottom-right-radius: 30px;
	border-top-right-radius: 30px;
}
.btn-group-toggle>.btn, .btn-group-toggle>.btn-group>.btn {
	/* border-radius: 30px; */
	border: 1px solid #6c757d;
	color: #6c757d;
	background-color: #fff;
	outline: unset;
}
.btn-group.btn-toggle.gender {
	width: 95%;
}
select#CustomSelect {
	outline: unset;
	border: 1px solid #6c757d;
	border-radius: 4px;
	box-shadow: unset;
}
.add-dependent input[type=text],
.add-dependent input[type=date] {
	width: 100%;
	padding: 8px;
	border-radius: 5px;
	outline: unset;
}
.add-title-one {
	font-size: 22px;
	font-weight: 500;
}
.add-title-two {
	font-size: 16px;
	font-weight: 500;
}
@media only screen and (min-width: 736px) and (max-width: 768px) {
	.card-body.add-dependent .col-md-6 {
		max-width: 100%;
		flex: 0 0 100%;
	}
	.card-body.dependent .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
 }
}
/* register */
.terms-and-policy span.terms, .terms-and-policy a {
	color: #e97a24;
}
span.agree {
	font-size: 16px;
	margin-left: 6px;
}
input[type=checkbox]#agree {
	vertical-align: middle;
}
.service-col .col-md-3 {
    text-align: center;
}
.service-col .service-btn {
	width: 100%;
	background-color: #20c0f3;
	color: #fff;
	border-radius: 5px;
	padding: 6px 0px 12px 0px;
	font-size: 18px;
	border-top: 1px;
	border-left: 1px;
	border-right: 1px; 
}
.service-col .card {
	margin-left: 4px;
	margin-right: 4px;
	height: 720px;
}
.learn-more {
	color: #20c0f3;
}
.service-content-five .see-btn {
	background-color: #20c0f3;
	border-radius: 30px;
	width: 90%;
	padding: 6px 12px;
	border: 1px;
	color: #fff;
}
.service-content-six .schedule-btn {
	background-color: #fff;
	border-radius: 30px;
	width: 90%;
	padding: 6px 12px;
	border: 1px solid #20c0f3;
	color: #20c0f3;
}
.service-content-two span {
	font-size: 28px;
	margin-right: 10px;
}
.service-content-three .use {
	font-weight: 500;
}
.service-content-three p {
	margin-bottom: 4px;
}
.service-content-one p {
	min-height: 115px;
	overflow: hidden;
	padding: 0px 10px;
}
.service-content-two {
	background-color: #f7f7f7;
	min-height: 120px;
	overflow: hidden;
}
.service-content-two p {
	margin-bottom: 0px;
	font-size: 14px;
}
.service-content-two span.less {
	font-size: 16px;
	margin-right: 0px;
}
.choose-service .choose-text {
	font-size: 20px;
	text-align: center;
	font-weight: 500;
}
.visit-col .visit-btns {
	color: #272b41;
	background-color: #fff;
	width: 80%;
	margin-bottom: 15px;
	border: 1px solid #dcdcdc;
	border-radius: 30px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	padding: 8px 14px;
	outline: unset;
	cursor: pointer;
}
.visits input.form-check-input {
	margin-left: -40px;
}
.visits span.visit-rsn {
	text-align: center;
	display: block;
}
.visit-col .back-left {
	color: #09e5ab;
}
.visit-questions p {
	text-align: center;
}
.visit-questions p.ques {
	font-size: 20px;
}
.progress-text {
	color: #00e65b;
	text-align: center;
	font-size: 12px;
	display: block;
	margin-bottom: 5px;
}
.visit-progress .col-md-2:first-child {
	padding-left: 15px;
}
.visit-progress .col-md-2 {
	padding-left: 0px;
	padding-right: 10px;
	flex: 0 0 14%;
	max-width: 14%;
}
.ques-icon i.fa.fa-question:before {
	border: 4px solid;
	border-radius: 50%;
	padding: 16px 24px;
}
.ques-icon i.fa.fa-question {
	font-size: 55px;
	margin: 0 auto;
	display: block;
	text-align: center;
	color: #bdbdbd;
}
.upload-btns {
	background-color: #15558d;
	border-radius: 30px;
	width: 60%;
	padding: 10px 12px;
	border: 1px;
	color: #fff;
	margin: 0 auto;
	display: block;
}
.photo {
	text-align: center;
}
.uploads p {
	text-align: center;
	padding: 0 50px;
}
.photo p.photo-help {
	font-size: 20px;
	font-weight: 500;
}
.bck-btn {
	  background-color: transparent;
    border-radius: 50px;
    color: #20c0f3;
    cursor: pointer;
    display: block;
    font-size: 13px;
    font-weight: 600;
    margin: 0 auto;
    padding: 10px 15px;
    position: relative;
    transition: .3s;
    text-align: center;
		width: 220px;
		border: 2px solid #20c0f3;
}
.continue-btn {
	background-color: #20c0f3;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 13px;
    font-weight: 600;
    margin: 0 auto;
    padding: 10px 15px;
    position: relative;
    transition: .3s;
    text-align: center;
		width: 220px;
		border: 2px solid;
}
.health-questions p {
	text-align: center;
}
.health-questions p.ques {
	font-size: 20px;
}
.add-medical {
	color: #15558d;
	cursor: pointer;
}
.next-btn {
	background-color: #15558d;
	float: right;
	border: 1px solid #15558d;
	color: #fff;
	padding: 3px 24px;
}
.doc-questions .ques {
	text-align: center;
	font-size: 22px;
}
.intake-box {
	border: 1px solid;
	text-align: center;
	padding: 4px 0px;
	margin-left: 6px;
}
.my-doc {
	margin-left: 60px;
}
.type-visit .visits {
	text-align: center;
}
.typess p {
	font-size: 20px;
	margin-bottom: 4px;
}
.visit-like {
	font-size: 20px;
}
.type-visit .visits i.fa,
.type-visit .visits i.fas {
	font-size: 30px;
	border: 1px solid;
	padding: 12px;
	border-radius: 40px;
}
.pay-ctn-btn {
	background-color: #15558d;
	color: #fff;
	border: 1px solid;
	padding: 5px 24px;
}
.confirm-visit {
	text-align: center;
}
.google-cal,
.outlook-cal {
	background-color: transparent;
	border: 1px solid #15558d;
	border-radius: 4px;
	padding: 4px 12px;
}
.yahoo-cal {
	background-color: transparent;
	border: 1px solid #09e5ab;
	border-radius: 4px;
	padding: 4px 12px;
}
.video-learn,
.need-tel {
	color: #15558d;
}
.appoint-start-time {
	font-size: 18px;
}
.visit-input-wrapper .input-icon {
	position: absolute;
	left: 3px;
	top: calc(50% - 0.5em);
	color: #09e5ab;
}
.visit-input-wrapper input {
	padding-left: 30px;
	border-bottom: 2px solid #09e5ab !important;
	border: unset;
	width: 40%;
	outline: unset;
}
.visit-input-wrapper{
	position: relative;
	left:30%;
}
.view-my-doc-profile .profile-doc-btn {
    border: 2px solid #0de0fe;
    background: transparent;
    color: #0de0fe;
    padding: 4px 18px;
		border-radius: 6px;
		float: right;
}
.doc-name i.fas.fa-check-circle.doc-check {
	color: #09e5ab;
}
.my-acc .acc-detail {
	font-size: 20px;
}
.go-visit {
	text-align: center;
	color: red;
}
i.fas.fa-video.appoint-video {
	font-size: 26px;
	text-align: center;
	display: block;
}
.any-intake-box {
	border: 1px solid;
	text-align: center;
	padding: 4px 4px;
	margin-left: 6px;
	width: 10%;
}
span.call-duration.float-right {
	display: inline-block;
	font-size: 22px;
	margin-top: 0px;
	position: absolute;
	right: 0;
	left: unset;
	color: #e97a24;
}


.toggle-voice-video .switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;
}

.toggle-voice-video .switch input {display:none;}

.toggle-voice-video .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e97a24;
  -webkit-transition: .4s;
  transition: .4s;
   border-radius: 34px;
}

.toggle-voice-video .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

.toggle-voice-video input:checked + .slider {
  background-color: #2ab934;
}

.toggle-voice-video input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.toggle-voice-video input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.toggle-voice-video .slider:after
{
 content:'VOICE';
 color: white;
 display: block;
 position: absolute;
 transform: translate(-50%,-50%);
 top: 50%;
 left: 50%;
 font-size: 10px;
 font-family: Verdana, sans-serif;
}

.toggle-voice-video input:checked + .slider:after
{  
  content:'VIDEO';
}
.toggle-voice-video {
	display: inline-block;
	font-size: 30px;
	margin-top: 4px;
	position: absolute;
	left: 0;
}
.health-profile-share {
	text-align: center;
	display: block;
	margin: 19px auto 35px auto;
	background-color: #5be3fe54;
	padding: 18px 100px 2px 20px;
}
.health-profile-share a {
	color: #15558d;
}
.health-profile-share i.fa.fa-check.share-health-prof {
	background-color: #15558d;
	padding: 2px;
	color: #fff;
	border: 1px solid #15558d;
}
.find-doc .clinic-services span {
    border: 1px solid #f7f7f7;
    border-radius: 4px;
    display: inline-block;
    font-size: 12px;
    padding: 6px 10px;
    background: #f7f7f7;
}


/* custom sidebar for package and tests integration values  */

.search_filter_side_bar {
    display: flex;
    flex-direction: row;
    word-wrap: break-word;
    display: inline-flexbox;
    position: relative; 
    display: block; 
    left: 10px; 
    top: 0px; 
    z-index: 9;
    width: 100%;
}


/* loaders custom background */
.customBg .modal-content{
    background-color:transparent !important;
    border-color:transparent !important;
}
.customBg .modal-body{
      display: flex;
      justify-content: center;
      align-items: center;
       height: inherit;
     }
    
/* set the flex box with same heigth */
.feature-box .inner-feature-box
{
	min-height: 335px;
}
/* about us */
.aboutus-col .about-right h1 {
	font-size: 36px;
	font-weight: 900;
	color: #3065b5;
	max-width: 600px;
	padding-bottom: 20px;	
	padding-top: 40px;
}
.photoproperty{
	padding-top: 100px;
}
.photoproperty_1
{
width:300px;
padding-bottom:0px;

}

.photoproperty_2
{
	width:700px;

}
.textproperty
{
	color: #e97a24 !important;
	font-size: 18px;
    font-weight:200px !important;

}

.errorcolor {
	color:red;
}

/* Here is css what are you looking for you can fix height and width according to your requirement; */

.blog-btn{
	position: absolute;
	left: 50%;
	transform: translateX(-50%) rotate(-45deg);
	-webkit-transform: translateX(-50%) rotate(-45deg);
	-ms-transform: translateX(-50%) rotate(-45deg);
	bottom: -22px;
	background-color: #3065b5;
	width: 45px;
	height: 45px;
	border-radius: 5px 15px 5px 15px;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;  
}

.inbtn i{
	color: #e97a24;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);  
}




/* .doctor-slider  .blog-btn i {
	color: #e97a24;
	transform: rotate(45deg);
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
	  
} */
	
.notify .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: none !important;
	border-left: none !important;
    /* border-right: 0.3em solid transparent; */
    /* border-bottom: 0; */
    /* border-left: 0.3em solid transparent; */
}

.notify .dropdown-toggle .nav-link { 
	padding:0.5rem 0rem 0.5rem 1rem
} 

/* article home screen */
.article-slider .slick-slide{
	display: block;
	margin-left: 0;
	padding: 10px;
	width: 310px;
}
.article-slider .slick-prev, .article-slider .slick-next{top:inherit}

.article-col .article-profile-widget {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius:4px;
    margin-bottom:30px;
    position:relative;
    -webkit-transition:all .3s ease 0s;
    -moz-transition:all .3s ease 0s;
    -o-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
	padding: 0;
	border-radius: 30px 7px;
}
.article-col .article-img {
    position: relative;
    overflow: hidden;
    z-index: 1;    
    border-bottom: 3px solid #e97a24;    
    border-top-left-radius: 30px;
    border-top-right-radius: 7px;
}
.article-col .article-img img {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    width: 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 7px;
}

.article-col .pro-content {
    padding: 15px;
}
.article-col .article-profile-widget .rating {
    color: #858585;
    font-size: 14px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #c4c4c4;
}
.article-col .available-info {
	font-size: 14px;
    color: #858585;
    font-weight: 400;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}
.article-profile-widget {
	background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    margin-bottom: 30px;
    position: relative;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    padding: 15px;
}
.article-img {
	position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 4px;
}
.article-img img {
	border-radius: 4px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    width: 100%;
}
.article-img:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.article-profile-widget .fav-btn {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #fff;
    width: 30px;
    height: 30px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    border-radius: 3px;
    color: #2E3842;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    opacity: 0;
    visibility: hidden;
	z-index: 99;
}
.article-profile-widget:hover .fav-btn {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.article-profile-widget .fav-btn:hover {
	background-color: #fb1612;
	color: #fff;
}

.article-profile-widget
.article-profile-widget .blog-btn i {
	color: #e97a24;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}
.pro-content {
    padding: 15px 0 0;
}
.pro-content .title {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;
}
.article-profile-widget .pro-content .title a {
    display: inline-block;
    color: #3065b5;
    font-size: 20px;
    font-weight: 700;
}

.article-profile-widget .verified {
	color: #28a745;
	margin-left: 3px;
}
.article-profile-widget p.speciality {
	font-size: 14px;
    color: #858585;
    margin-bottom: 5px;
    min-height: 20px;
}
.rating {
    list-style: none;
    margin: 0 0 7px;
    padding: 0;
    width: 100%;
}
.rating i {
    color: #e97a24;
}
.rating i.filled {
    color: #e97a24;
}
.article-profile-widget .rating {
	color: #757575;
    font-size: 14px;
    margin-bottom: 15px;
}
.article-profile-widget .rating i {
	font-size: 14px;
}
.available-info {
    font-size: 13px;
    color: #757575;
    font-weight: 400;
    list-style: none;
    padding: 0;
    margin-bottom: 15px;
}
.available-info li + li {
	margin-top: 5px;
}
.available-info li i {
	width: 22px;
	color: #3065b5;
}

.view-btn {
    color: #e97a24;
    font-size: 13px;
    border: 2px solid #e97a24;
    text-align: center;
    display: block;
    font-weight: 500;
	padding: 6px;
}
.article-section-doctor .article-profile-widget {
    box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    border-radius: 30px 7px;
}