 .sticky {
    position:sticky;
   top:0;
   z-index:100;
   }

/*@media screen and (max-width:780px){
.main-nav{
    display:flex;
    position: absolute;
    top: 15px;
    left: 35px;
}

.nav-links {
    display: none;
}
.nav-links-mobile {
    position: absolute;
    display:block;
    list-style: none;
    background-color: blue;
    left: 0;
    top: 75px;
    transform: all 0.5s ease-out;
    width: 100%;

}
.home,
.infrastructure,
.test,
.packages,
.faqs,
.articles,
.login {
color:white;
text-align:center;
padding: 32px;
width: 100%;
transition: all 0.5s ease;


}
.login {
    border: none;

}
#mobile-btn {
display: block;
position: absolute;
font-size: 30px;
}
}
/*.mobile-menu-link {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
}

.mobile-menu-link {

background-color:white;
height:20rem;
display: grid;
grid-column: 2/5;
align-items: centre;
padding-left: 3rem;
transition: all 2s linear;
transform-origin:top;
box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px
            rgba(0,0,0,0.3) 0px 30px 60px -30px;


}*/
@media(max-width:800px){
    nav .main-nav {
        height:75vh;
        position: fixed;
        top: 0;
        right: 25;
        left:25 ;
        z-index: 100;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #3065b5;
        transition:top 1s ease;
    
    }
     .slide-bar {
        background-color: cornflowerblue;
        display: flexbox;
    }
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    /* vertical-align: 0.255em; */
    content: "";
    /* border-top: 0.3em solid; */
    /* border-right: 0.3em solid transparent; */
    /* border-bottom: 0; */
    /* border-left: 0.3em solid transparent; */
}