input[type="checkbox"]{
    display: inline-block;
    /* display: 'inline-flex'; */
    flex-Direction: 'row';
    margin-left: -75px;
    position: absolute;
    margin-top: 5px; 
    
}
.labelstyle{
    margin-left:-75px;
   padding-left: 50px;
    display: inline-block;
    text-align: centre;
    z-index:200px;
    text-indent: -15px;
    top: -120px;
      /* position:absolute;  */
}
.card-title{
    margin-top: 20px;
    margin-bottom: 20px;
}
.btn1{
    display: flex;
    justify-Content: right;

    padding-left: 3px;
    margin-left: auto;
    position: absolute;
    left: auto;
    right: 2px;
    bottom: 75px;
}
.btn2{
    display:flex;
    justify-Content: left;
    margin-left: auto;
    position: absolute;
    bottom: 30px;
    left:130px;
}