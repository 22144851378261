.style {
    display: flex;
    flex-direction: row;
    word-wrap: break-word;
    display: inline-flexbox;
    position: relative; 
    display: block; 
    left: 10px; 
    top: 0px; 
    z-index: 9;
    width: 100%;
}